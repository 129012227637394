import { Document, Image, Page, Styles, Text, View } from "@react-pdf/renderer";
import { usePropertyValuation } from "src/apps/AppFlow/pages/hooks/usePropertyValuation";
import PdfLogo from "src/apps/BrokerDashboard/pages/Logo";
import FinancialProfile from "src/apps/BrokerDashboard/pages/Marketing/components/FinancialProfile";
import { computeCostCalculatorRows } from "src/calculators/cost-calculator";
import { DropdownSelector, LocationInput, Skeleton, TextInput } from "src/components";
import { BackButton } from "src/components/BackButton";
import Dropzone, { FileWithEnumType } from "src/components/Dropzone";
import { CURRENCY_MASK, PERCENTAGE_MASK } from "src/components/TextInput";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { Label } from "src/components/ui/label";
import { useCurrentRate } from "src/hooks";
import { useBrokerage } from "src/hooks/useBrokerage";
import useToggler from "src/hooks/useToggler";

import {
  constants,
  calculators,
  entities,
  enums,
  finance,
  formatters,
  selectors,
  types,
} from "@fraction/shared";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import fraction from "src/api/fraction";
import { tw } from "src/documents/styles";

import { ReactNode, memo, useCallback, useState } from "react";
import { useAuth } from "src/auth";
import { PDFViewer } from "src/components/PDFViewer";
import { useEventualState } from "src/hooks/useEventualState";
import { useMutation } from "src/lib/react-query";
import { cn } from "src/utilities/shadcnUtils";

type Style = Styles[keyof Styles];

export const PropertyReport = ({ backButton = true }: { backButton?: boolean }) => {
  useDocumentTitle("Marketing | Scenario Planner");

  const [address, setAddress] = useState<types.ExtractedPropertyAddress>();
  const [termLength, setTermLength] = useState<"5" | "4" | "3">("3");

  const propertyAddress = formatters.property.formattedAddress(address);

  const { user, isLoadingUser, isAuthenticated } = useAuth();
  const { data: brokerage } = useBrokerage();

  const trackUserEvent = useMutation({
    mutationFn: async () => {
      if (!isAuthenticated) {
        return;
      }
      return fraction.trackUserEvent({ type: enums.EventType.DOWNLOADED_SCENARIO_PLANNER });
    },
  });

  const propertyValueReport = usePropertyValuation(propertyAddress);
  const { rate: minRate, isLoading: rateIsLoading } = useCurrentRate("Ontario", {
    termLength: Number(termLength) as 5 | 4,
    lenderId: enums.LenderName.FRACTION,
  });

  const isLoading = propertyValueReport.isLoading || rateIsLoading || isLoadingUser;

  const [propertyValue, setPropertyValue] = useEventualState(
    propertyValueReport?.data ? (propertyValueReport?.data / 100).toString() : undefined
  );

  const handleChangePropertyValue = useCallback((value: string) => {
    const number = formatters.number.getNumberFromString(value);
    setPropertyValue(value);
    setPrincipal((prev) =>
      prev ? prev : (number * (minRate?.maximumLTV || constants.MAX_ALLOWABLE_LTV)).toString()
    );
  }, []);

  const { Toggle: ShowImageToggle, on: showImage } = useToggler({
    defaultValue: true,
    id: "show-image",
    label: "Show image",
    direction: "left",
  });
  const { Toggle: CapitalizeFeesToggle, on: capitalizeFees } = useToggler({
    defaultValue: true,
    id: "capitalize-fees",
    label: "Capitalize origination fee",
    direction: "left",
  });
  const { Toggle: RoundImageToggle, on: roundImage } = useToggler({
    defaultValue: true,
    id: "round-image",
    label: "Rounded",
    direction: "left",
  });

  const [years, setYears] = useState("25");
  const [appreciation, setAppreciation] = useState("6");
  const [existingMortgageBalance, setExistingMortgageBalance] = useState("0");
  const [paymentType] = useState<"amortizing" | "interestOnly" | "reverse">("reverse");
  const [mortgageType, setMortgageType] = useState<"purchase" | "refinance">("refinance");
  const [paymentFrequency, setPaymentFrequency] = useState<"monthly" | "biweekly" | "weekly">("monthly");
  const [rate, setRate] = useEventualState(
    minRate?.minimumRate ? (minRate?.minimumRate * 100)?.toString() : undefined
  );
  const [existingMortgagePayments, setExistingMortgagePayments] = useState("0");

  // const [downPayment, setDownPayment] = useEventualState(
  //   propertyValue ? (formatters.number.getNumberFromString(propertyValue) * 0.2).toString() : undefined
  // );

  const [principal, setPrincipal] = useEventualState(
    propertyValue && minRate?.maximumLTV
      ? (formatters.number.getNumberFromString(propertyValue) * minRate.maximumLTV).toString()
      : undefined
  );
  const monthlyRate = rate
    ? calculators.rates.convertAnnualToMonthlyCompounding(formatters.number.getNumberFromString(rate))
    : 0;
  const monthlyAmortizationPayment =
    principal && rate
      ? finance.pmt(formatters.number.getNumberFromString(principal), monthlyRate, 12 * Number(years), "end")
      : undefined;
  const [name, setName] = useEventualState(
    user?.fullName || user?.firstName ? `${user?.firstName} ${user?.lastName || ""}` : undefined
  );
  const [brokerageName, setBrokerageName] = useEventualState(
    brokerage?.brokerGroup?.tradeName || brokerage?.brokerGroup?.name
  );

  const amortizationPaymentForFrequency = monthlyAmortizationPayment
    ? paymentFrequency === "monthly"
      ? monthlyAmortizationPayment
      : paymentFrequency === "biweekly"
      ? monthlyAmortizationPayment / 2
      : monthlyAmortizationPayment / 4.3333
    : undefined;
  const monthlyInterestOnlyPayment =
    principal && rate
      ? (formatters.number.getNumberFromString(principal) * formatters.number.getNumberFromString(rate)) /
        100 /
        12
      : undefined;

  const interestOnlyPaymentForFrequency = monthlyInterestOnlyPayment
    ? paymentFrequency === "monthly"
      ? monthlyInterestOnlyPayment
      : paymentFrequency === "biweekly"
      ? monthlyInterestOnlyPayment / 2
      : monthlyInterestOnlyPayment / 4.3333
    : undefined;

  const [agentImage, setAgentImage] = useState<FileWithEnumType | null>(null);
  const buffer = useQuery({
    queryKey: ["agentImage", agentImage?.name],
    enabled: !!agentImage,
    queryFn: async () => {
      if (!agentImage) {
        return;
      }
      return URL.createObjectURL(agentImage);
    },
  });

  const numPrincipal = principal ? formatters.number.getNumberFromString(principal) : 0;
  const numPropertyValue = propertyValue ? formatters.number.getNumberFromString(propertyValue) : 0;
  const cappedLoanAmount = Math.min(
    Math.min(
      numPrincipal,
      (numPropertyValue || 1000000) * (minRate?.maximumLTV || constants.MAX_ALLOWABLE_LTV)
    ),
    constants.MAX_LOAN_AMOUNT / 100
  );

  const maxLoanAmount = Math.min(
    (numPropertyValue || 1000000) * (minRate?.maximumLTV || constants.MAX_ALLOWABLE_LTV),
    constants.MAX_LOAN_AMOUNT / 100
  );

  const fees = selectors.application.selectApplicationFees({
    estimatedHomeValue: formatters.number.getCents(numPropertyValue),
    loanAmount: formatters.number.getCents(cappedLoanAmount),
  });

  const rows = computeCostCalculatorRows({
    rate: rate ? formatters.number.getNumberFromString(rate) / 100 : 0,
    loanAmount: cappedLoanAmount + (capitalizeFees ? formatters.number.getDollars(fees.origination) : 0),
    homeValue: numPropertyValue,
    appreciationRate: Number(appreciation) / 100,
    termLength: Number(termLength),
  });

  return (
    <div className="p-6">
      {backButton ? (
        <BackButton to="/marketing" className="mb-4" label="Sales tools - Scenario Planner" />
      ) : null}
      <div className="flex flex-row flex-wrap mt-6 gap-x-8">
        <div className="flex flex-col w-full flex-1 lg:max-w-[400px]">
          <ShowImageToggle className="mb-4" />
          <LocationInput
            name="Address"
            label="Property address"
            value={address ? formatters.property.formattedAddress(address, { premise: true }) : undefined}
            onSelect={setAddress}
          />
          <DropdownSelector
            defaultValue={mortgageType}
            value={mortgageType}
            label="Mortgage type"
            onChange={setMortgageType}
            options={[
              { value: "purchase", label: "Purchase" },
              { value: "refinance", label: "Refinance" },
            ]}
          />
          {/*
          <DropdownSelector
            defaultValue={paymentType}
            value={paymentType}
            label="Payment type"
            onChange={setPaymentType}
            options={[
              { value: "amortizing", label: "Amortizing" },
              { value: "interestOnly", label: "Interest Only" },
              { value: "reverse", label: "Reverse / No Payments" }
            ]}
          />
          */}

          <TextInput
            maskOptions={CURRENCY_MASK}
            label="Property value"
            value={propertyValue}
            onChange={handleChangePropertyValue}
          />
          {mortgageType === "refinance" ? (
            <TextInput
              maskOptions={CURRENCY_MASK}
              label={`Existing mortgage balance ${
                existingMortgageBalance && propertyValue
                  ? `(${formatters.number.getPercentageFromDecimal(
                      formatters.number.getNumberFromString(existingMortgageBalance) /
                        formatters.number.getNumberFromString(propertyValue),
                      { digits: 1 }
                    )} of property value)`
                  : ""
              }`}
              value={existingMortgageBalance}
              onChange={setExistingMortgageBalance}
            />
          ) : null}
          <TextInput
            errorSpacing={false}
            maskOptions={CURRENCY_MASK}
            label="Existing monthly mortgage payments"
            value={existingMortgagePayments}
            onChange={setExistingMortgagePayments}
          />
          <p className="font-normal text-xs text-gray-600 mb-4 pr-4 pl-0.5 mt-1 rounded-lg">
            Note: You can include any monthly payments for loans you plan to pay off with Fraction for this.
          </p>

          {/*{mortgageType === "purchase" ? (
            <TextInput
              maskOptions={CURRENCY_MASK}
              label={`Downpayment ${
                downPayment && propertyValue
                  ? `(${formatters.number.getPercentageFromDecimal(
                      formatters.number.getNumberFromString(downPayment) /
                        formatters.number.getNumberFromString(propertyValue),
                      { digits: 0 }
                    )} of property value)`
                  : ""
              }`}
              value={downPayment}
              onChange={setDownPayment}
            />
          ) : null}*/}

          <TextInput
            errorSpacing={false}
            maskOptions={CURRENCY_MASK}
            label={`Fraction Principal ${
              principal && propertyValue
                ? `(${formatters.number.getPercentageFromDecimal(
                    formatters.number.getNumberFromString(principal) /
                      formatters.number.getNumberFromString(propertyValue),
                    { digits: 1 }
                  )} of property value)`
                : ""
            }`}
            value={principal}
            onChange={setPrincipal}
          />
          <p
            className={cn(
              "font-normal text-xs text-gray-600 mb-2 pr-4 pl-0.5 mt-1 rounded-lg",
              cappedLoanAmount < numPrincipal && "text-red"
            )}
          >
            Note: Fraction only lends up to a maximum of{" "}
            {formatters.number.getPercentageFromDecimal(minRate?.maximumLTV || constants.MAX_ALLOWABLE_LTV)}{" "}
            LTV with a {termLength} year term.{" "}
            {cappedLoanAmount < numPrincipal
              ? `We are capping the loan amount at ${formatters.number.getCurrencyFromNumber(
                  cappedLoanAmount,
                  { inputInDollars: true }
                )}`
              : ""}
          </p>
          <CapitalizeFeesToggle />
          <p className="font-normal text-xs text-gray-600 mb-6 pr-4 pl-0.5 mt-1 rounded-lg">
            The origination fee by default is added onto the loan amount. The conveyancing fee, ILR fee, and
            title insurance fee are paid out of proceeds. The appraisal fee is paid out of pocket.
          </p>
          <DropdownSelector
            value={termLength}
            label="Fraction term length"
            onChange={setTermLength}
            options={[
              { value: "5", label: "Five years" },
              { value: "4", label: "Four years" },
              { value: "3", label: "Three years" },
            ]}
          />
          <TextInput
            errorSpacing={false}
            maskOptions={PERCENTAGE_MASK}
            label="Fraction Rate"
            value={rate}
            onChange={setRate}
          />
          <p className="font-normal text-xs text-gray-600 mb-4 pr-4 pl-0.5 mt-1 rounded-lg">
            {minRate?.minimumRate ? formatters.number.getPercentageFromDecimal(minRate?.minimumRate) : "This"}{" "}
            is Fraction's currently posted rate for the {termLength} year term. These rates do change from
            time to time.
          </p>
          {paymentType !== "reverse" ? (
            <DropdownSelector
              defaultValue={paymentFrequency}
              value={paymentFrequency}
              label="Payment frequency"
              onChange={setPaymentFrequency}
              options={[
                { value: "monthly", label: "Monthly" },
                { value: "biweekly", label: "Bi-Weekly" },
                { value: "weekly", label: "Weekly" },
              ]}
            />
          ) : null}
          <TextInput
            maskOptions={PERCENTAGE_MASK}
            label="Property appreciation"
            value={appreciation}
            onChange={setAppreciation}
          />
          {paymentType === "amortizing" ? (
            <TextInput type="number" label="Amortization period (years)" value={years} onChange={setYears} />
          ) : null}
          <div className="mt-4">
            <TextInput className="mt-1" label="Your name" value={name} onChange={setName} />
          </div>
          <TextInput label="Your brokerage" value={brokerageName} onChange={setBrokerageName} />
          <div className="flex flex-row justify-between mb-2">
            <Label>Your picture/logo</Label>
            <RoundImageToggle />
          </div>
          <div className="border border-1 border-gray-400 rounded p-4">
            <Dropzone className="w-full" multi={false} onDrop={setAgentImage} />
            <img className="w-6 rounded-full" src={buffer?.data} />
          </div>
        </div>

        <div className="h-[85svh] w-full flex-1 lg:min-w-[400px] lg:max-w-[700px] rounded-md border border-gray-300">
          {isLoading ? (
            <Skeleton className="h-[85svh] w-[95%]" />
          ) : (
            <div className="relative h-full w-full">
              <PDFViewer
                filename="Fraction Scenario Planner"
                download="Download your report here"
                className="h-full w-full"
                onDownload={trackUserEvent.mutate}
              >
                <PropertyReportDocument
                  name={name}
                  brokerageName={brokerageName}
                  roundImage={roundImage}
                  bufferData={buffer?.data}
                  address={address}
                  showImage={showImage}
                  propertyValue={
                    propertyValue ? formatters.number.getNumberFromString(propertyValue) : undefined
                  }
                  principal={principal}
                  mortgageType={mortgageType}
                  existingMortgageBalance={formatters.number.getNumberFromString(existingMortgageBalance)}
                  rate={rate}
                  paymentType={paymentType}
                  paymentFrequency={paymentFrequency}
                  cappedLoanAmount={cappedLoanAmount}
                  maxLoanAmount={maxLoanAmount}
                  minRate={minRate}
                  termLength={termLength}
                  interestOnlyPaymentForFrequency={interestOnlyPaymentForFrequency}
                  numPrincipal={numPrincipal}
                  rows={rows}
                  appreciation={appreciation}
                  amortizationPaymentForFrequency={amortizationPaymentForFrequency}
                  propertyAddress={propertyAddress}
                  existingMortgagePayments={formatters.number.getNumberFromString(existingMortgagePayments)}
                  capitalizeFees={capitalizeFees}
                />
              </PDFViewer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PropertyReportDocument = memo(
  ({
    name,
    brokerageName,
    roundImage,
    bufferData,
    address,
    showImage,
    propertyValue,
    principal,
    mortgageType,
    existingMortgageBalance,
    rate,
    paymentType,
    paymentFrequency,
    cappedLoanAmount,
    minRate,
    termLength,
    interestOnlyPaymentForFrequency,
    numPrincipal,
    rows,
    appreciation,
    amortizationPaymentForFrequency,
    propertyAddress,
    existingMortgagePayments,
    maxLoanAmount,
    capitalizeFees,
  }: {
    name?: string;
    brokerageName?: string;
    roundImage?: boolean;
    bufferData?: Buffer;
    address?: entities.Property;
    showImage?: boolean;
    propertyValue?: number;
    cappedLoanAmount?: number;
    maxLoanAmount?: number;
    existingMortgageBalance?: number;
    capitalizeFees?: boolean;
    // i am sorry for my sins but i am lazy and there are SOOO many props
  } & any) => {
    const fees = selectors.application.selectApplicationFees({
      estimatedHomeValue: formatters.number.getCents(propertyValue),
      loanAmount: formatters.number.getCents(cappedLoanAmount),
    });
    const totalFees = _.sum(Object.values(fees));
    const feesDeductedFromLoan = fees.ilr + fees.titleInsurance + fees.conveyancing;
    const finalLoanAmount =
      cappedLoanAmount + (capitalizeFees ? formatters.number.getDollars(fees.origination) : 0);

    const Footer = () => (
      <View style={tw("mt-auto flex flex-row items-center justify-between")}>
        <View>
          <PdfLogo />
          <Text style={tw("text-sm font-semibold mt-2 text-[#82c882]")}>www.fraction.com</Text>
        </View>
        <View style={tw("flex flex-row")}>
          <View style={tw("mr-4 flex flex-col items-end justify-center")}>
            <Text style={tw("text-base font-semibold text-end")}>{name}</Text>
            <Text style={tw("text-base text-end")}>{brokerageName}</Text>
          </View>
          {bufferData ? (
            <Image
              style={tw(`h-14 max-w-32 object-contain ${roundImage ? "rounded-full" : ""}`)}
              src={bufferData}
            />
          ) : null}
        </View>
      </View>
    );

    return (
      <Document>
        <Page size="LETTER" style={tw("p-12 font-sans")}>
          <Text style={tw("text-4xl font-bold font-serif leading-snug mb-2")}>Fraction Scenario Planner</Text>
          {address ? (
            <>
              <View style={tw("flex flex-row bg-green-50 rounded")}>
                {showImage ? (
                  <Image
                    style={tw("h-48 w-48 rounded")}
                    src={`https://maps.googleapis.com/maps/api/streetview?key=AIzaSyCpLsRv0TaXWPX6j-a9Xw7834Hm2mG4xL0&size=600x600&location=${propertyAddress}&fov=100`}
                  />
                ) : null}

                <View
                  wrap
                  style={tw("flex-1 p-6 px-8 flex flex-wrap items-start justify-start gap-y-2 flex-row")}
                >
                  <View style={tw("flex-col h-full justify-between flex-1")}>
                    <View style={tw("mr-8")}>
                      <Text style={tw("text-lg font-bold")}>Property Address</Text>
                      <Text style={tw("flex-initial text-base font-medium")}>
                        {formatters.property.line1FormattedAddress(address)}{" "}
                        {formatters.property.line2FormattedAddress(address)}
                      </Text>
                    </View>

                    <View style={tw("mr-8")}>
                      <Text style={tw("text-lg font-bold")}>Est. Property Value</Text>
                      <Text style={tw("flex-initial text-base font-medium")}>
                        {formatters.number.getCurrencyFromNumber(propertyValue, {
                          inputInDollars: true,
                        })}
                      </Text>
                    </View>
                  </View>
                  <View style={tw("flex-col h-full justify-between")}>
                    <View style={tw("mr-8")}>
                      <Text style={tw("text-lg font-bold")}>Assessed Value</Text>
                      <Text style={tw("flex-initial text-base font-medium")}>
                        {formatters.number.getCurrencyFromNumber(propertyValue, { inputInDollars: true })}
                      </Text>
                    </View>
                    <View style={tw("mr-8")}>
                      <Text style={tw("text-lg font-bold")}>Interest Rate</Text>
                      <Text style={tw("flex-initial text-base font-medium")}>
                        {rate
                          ? formatters.number.getPercentageFromDecimal(
                              formatters.number.getNumberFromString(rate) / 100
                            )
                          : "?"}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              {/*<View style={tw("flex flex-col bg-green-50 rounded mt-4 p-6")}>
                <Text style={tw("text-xl font-black mb-1")}>Property Details</Text>
                <View style={tw("flex flex-row flex-wrap space-between")}>
                  <LabeledValue label="Property type" value={propertyReport?.houseStyle?.split(" ")?.[0]} />
                  <LabeledValue
                    label="Bedrooms"
                    value={propertyReport?.bedroomsTotal ?? propertyReport?.bedroomsTotalEst}
                  />
                  <LabeledValue
                    label="Bathrooms"
                    value={propertyReport?.bathroomsTotal ?? propertyReport?.bathroomsTotalEst}
                  />
                  <LabeledValue label="Year built" value={propertyReport?.yearBuiltActual} />
                  <LabeledValue
                    label="Condo fees"
                    value={
                      propertyReport?.condoFees?.cents || propertyReport?.condoFeesEst?.cents
                        ? formatters.number.getCurrencyFromNumber(
                            propertyReport?.condoFees?.cents || propertyReport?.condoFeesEst?.cents
                          )
                        : undefined
                    }
                  />
                  <LabeledValue
                    style={tw("mr-24")}
                    label="Floor space"
                    value={
                      propertyReport?.livingArea ?? propertyReport?.livingAreaEst
                        ? `${propertyReport?.livingArea ?? propertyReport?.livingAreaEst} sqft`
                        : undefined
                    }
                  />
                  <LabeledValue
                    style={tw("mr-24")}
                    label="Basement"
                    value={propertyReport?.basement ? `${propertyReport?.basement} sqft` : undefined}
                  />
                </View>
              </View>*/}
              {/*<View style={tw("flex flex-col bg-green-50 rounded mt-4 p-6")}>
                <Text style={tw("text-xl font-black mb-1")}>Property Stats</Text>
                <View style={tw("flex flex-row flex-wrap")}>
                  <LabeledBox
                    label={`Avg ${
                      propertyReport?.cityStats?.year || ""
                    } value of a ${propertyReport?.cityStats?.assessmentClass?.toLowerCase()} in ${
                      propertyReport?.cityName
                    }`}
                    value={
                      propertyReport?.cityStats?.avgAssessedValue?.cents
                        ? formatters.number.getCurrencyWords(
                            propertyReport?.cityStats?.avgAssessedValue?.cents,
                            { short: true, digits: 1 }
                          )
                        : undefined
                    }
                  />
                  <LabeledBox
                    style={tw("ml-2")}
                    label={`Avg ${
                      (propertyReport?.cityStats?.year || 0) - 1
                    } value of a ${propertyReport?.cityStats?.assessmentClass?.toLowerCase()} in ${
                      propertyReport?.cityName
                    }`}
                    value={
                      propertyReport?.cityStats?.previousAvgAssessedValue?.cents
                        ? formatters.number.getCurrencyWords(
                            propertyReport?.cityStats?.previousAvgAssessedValue?.cents,
                            { short: true, digits: 1 }
                          )
                        : undefined
                    }
                  />
                  <LabeledBox
                    style={tw("ml-2")}
                    label={`Avg ${
                      propertyReport?.cityStats?.year || ""
                    } value of a ${propertyReport?.neighbourhoodAttributes?.assessmentClass?.toLowerCase()} in ${
                      propertyReport?.neighbourhoodName
                    }`}
                    value={
                      propertyReport?.neighbourhoodStats?.currentPredictedValue?.cents
                        ? formatters.number.getCurrencyWords(
                            propertyReport?.neighbourhoodStats?.currentPredictedValue?.cents,
                            { short: true, digits: 1 }
                          )
                        : undefined
                    }
                  />
                  <LabeledBox
                    style={tw("ml-2")}
                    label={`Avg ${
                      (propertyReport?.neighbourhoodStats?.year || 0) - 1
                    } value of a ${propertyReport?.neighbourhoodAttributes?.assessmentClass?.toLowerCase()} in ${
                      propertyReport?.neighbourhoodName
                    }`}
                    value={
                      propertyReport?.neighbourhoodStats?.previousPredictedValue?.cents
                        ? formatters.number.getCurrencyWords(
                            propertyReport?.neighbourhoodStats?.previousPredictedValue?.cents,
                            { short: true, digits: 1 }
                          )
                        : undefined
                    }
                  />
                </View>
              </View>*/}
            </>
          ) : null}
          {!address ? (
            <Text style={tw("text-xl text-center font-semibold leading-snug mt-2 bg-gray-200 p-6")}>
              Enter a property address to get started
            </Text>
          ) : /*(
            <View style={tw("flex flex-col bg-green-50 rounded mt-4 p-6")}>
              <Text style={tw("text-xl font-black mb-1")}>Fraction Mortgage Details</Text>
              <View style={tw("flex flex-row flex-wrap space-between")}>
                <LabeledValue
                  label="Fraction Principal"
                  value={formatters.number.getCurrencyFromNumber(principal, { inputInDollars: true })}
                />
                {mortgageType === "refinance" && principal ? (
                  <LabeledValue
                    label="Cash out"
                    value={formatters.number.getCurrencyFromNumber(
                      formatters.number.getNumberFromString(principal) -
                        formatters.number.getNumberFromString(existingMortgageBalance),
                      { inputInDollars: true }
                    )}
                  />
                ) : null}
                {rate ? (
                  <LabeledValue
                    label="Rate"
                    value={formatters.number.getPercentageFromDecimal(Number(rate) / 100)}
                  />
                ) : null}
                <LabeledValue
                  label="Mortgage type"
                  value={mortgageType === "purchase" ? "Purchase" : "Refinance"}
                />
                <LabeledValue
                  label="Payments"
                  value={
                    paymentType === "interestOnly"
                      ? "Interest only"
                      : paymentType === "reverse"
                      ? "No Payments"
                      : "Amortizing"
                  }
                />
                <LabeledValue
                  label={`${_.capitalize(paymentFrequency)} payments`}
                  value={
                    paymentType === "reverse"
                      ? "None"
                      : paymentType === "interestOnly"
                      ? formatters.number.getCurrencyFromNumber(interestOnlyPaymentForFrequency, {
                          inputInDollars: true,
                        })
                      : formatters.number.getCurrencyFromNumber(amortizationPaymentForFrequency, {
                          inputInDollars: true,
                        })
                  }
                />
              </View>
            </View>
          )*/ null}
          {mortgageType === "refinance" ? (
            <FinancialProfile
              monthlyDebtPaymentsBefore={existingMortgagePayments}
              monthlyDebtPaymentsAfter={0}
              termLength={Number(termLength)}
              cashToBorrower={Math.max(
                cappedLoanAmount - (existingMortgageBalance + feesDeductedFromLoan / 100),
                0
              )}
              loanAmount={cappedLoanAmount}
              style={tw("mt-4")}
            />
          ) : null}
          <Footer />
        </Page>
        <Page size="LETTER" style={tw("p-12 font-sans")}>
          <Text style={tw("text-2xl font-bold leading-snug")}>Could Fraction work for you?</Text>
          <Text style={tw("text-sm mt-2")}>
            Fraction offers a mortgage that allows you to access the equity in your home without monthly
            payments for up to 5 year terms—all without any prepayment penalties.{" "}
            {cappedLoanAmount < numPrincipal ? (
              <>
                Since Fraction only lends up to a maximum of{" "}
                {formatters.number.getPercentageFromDecimal(minRate?.maximumLTV ?? 0.44, {
                  digits: 0,
                })}{" "}
                loan-to-value (LTV) with a {termLength}-year term,{" "}
                <Text style={tw("font-bold")}>
                  the example below is capped at{" "}
                  {formatters.number.getCurrencyFromNumber(cappedLoanAmount, {
                    inputInDollars: true,
                  })}
                </Text>
                .
              </>
            ) : (
              ""
            )}
          </Text>
          <View style={tw("flex flex-col bg-green-50 rounded mt-4 p-6")}>
            <Text style={tw("text-xl font-black")}>Fraction details and requirements</Text>
            <Text style={tw("text-base mb-3")}>
              These are the basic qualifying criteria for whether an application might be accepted. Fraction
              offers different rates and maximum loan-to-values based on the the term length selected.
            </Text>
            <View style={tw("flex flex-row flex-wrap gap-2")}>
              <LabeledBox label="Min. credit score" value={constants.MINIMUM_CREDIT_SCORE} />
              {minRate ? (
                <LabeledBox
                  label={`${formatters.number.getPercentageFromDecimal(minRate.maximumLTV, {
                    digits: 1,
                  })} Max. LTV`}
                  value={
                    propertyValue && minRate?.maximumLTV
                      ? formatters.number.getCurrencyWords(
                          formatters.number.getNumberFromString(propertyValue) * minRate.maximumLTV,
                          { inputInDollars: true, digits: 0 }
                        )
                      : undefined
                  }
                />
              ) : null}
              <LabeledBox label="Residence" value="Primary" />
              <LabeledBox label="Charge position" value="1st mtg" />
              <LabeledBox
                label="Max loan amount"
                value={formatters.number.getCurrencyWords(maxLoanAmount, { inputInDollars: true })}
              />
            </View>
          </View>
          <View style={tw("mt-4")}>
            <Text style={tw("text-2xl font-black leading-snug")}>Cost calculator</Text>
            <Text style={tw("text-sm leading-snug mb-3 mt-1")}>
              If you originally borrowed{" "}
              <Text style={tw("font-bold")}>
                {formatters.number.getCurrencyFromNumber(finalLoanAmount, {
                  inputInDollars: true,
                })}
              </Text>{" "}
              {capitalizeFees ? "(incl. the origination fee) " : ""}from Fraction with a{" "}
              {formatters.number.getCurrencyFromNumber(propertyValue, { inputInDollars: true })} house, in{" "}
              <Text style={tw("font-bold")}>{termLength || 5}</Text> years time, your house will be worth
              around{" "}
              <Text style={tw("font-bold")}>
                {formatters.number.getCurrencyFromNumber(rows[rows.length - 1].homeValueAtYear, {
                  inputInDollars: true,
                })}
              </Text>{" "}
              based on annual growth of {appreciation}%. With an interest rate of{" "}
              {rate
                ? formatters.number.getPercentageFromDecimal(
                    formatters.number.getNumberFromString(rate) / 100
                  )
                : "?"}
              , you would then owe{" "}
              <Text style={tw("font-bold")}>
                {formatters.number.getCurrencyFromNumber(rows[rows.length - 1].totalLoanBalance, {
                  inputInDollars: true,
                })}
              </Text>{" "}
              and have{" "}
              <Text style={tw("font-bold")}>
                {formatters.number.getCurrencyFromNumber(
                  Math.max(rows[rows.length - 1].homeValueAtYear - rows[rows.length - 1].totalLoanBalance, 0),
                  {
                    inputInDollars: true,
                  }
                )}
              </Text>{" "}
              of equity.
            </Text>
            <View style={tw("flex flex-col text-center text-sm")}>
              <View style={tw("flex flex-row bg-green-50")}>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Year</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Total Interest</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Loan Balance</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Home Value</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Your Equity</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>APR*</Text>
                </View>
              </View>
              {rows.map((row: any, i: number) => (
                <View key={row.year} style={tw(`flex flex-row ${i % 2 === 0 ? "" : "bg-green-50"}`)}>
                  <View style={tw("flex-1 p-1")}>
                    <Text>{row.year}</Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>
                      {formatters.number.getCurrencyFromNumber(row.totalAccruedInterest, {
                        inputInDollars: true,
                      })}
                    </Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>
                      {formatters.number.getCurrencyFromNumber(row.totalLoanBalance, {
                        inputInDollars: true,
                      })}
                    </Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>
                      {formatters.number.getCurrencyFromNumber(row.homeValueAtYear, {
                        inputInDollars: true,
                      })}
                    </Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>
                      {formatters.number.getCurrencyFromNumber(row?.homeValueAtYear - row?.totalLoanBalance, {
                        inputInDollars: true,
                      })}
                    </Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>
                      {typeof row.apr === "string"
                        ? row.apr
                        : formatters.number.getPercentageFromDecimal(row.apr)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={tw("mt-4")}>
            <Text style={tw("text-2xl font-black leading-snug mb-2")}>Other fees</Text>
            <Text style={{ fontSize: 12, marginBottom: 2 }}>
              <Text style={{ fontWeight: "bold" }}>Origination fee:</Text>{" "}
              {formatters.number.getCurrencyFromNumber(fees.origination)}
            </Text>
            <Text style={{ fontSize: 12, marginBottom: 2 }}>
              <Text style={{ fontWeight: "bold" }}>Conveyancing fees:</Text>{" "}
              {formatters.number.getCurrencyFromNumber(fees.conveyancing)}
            </Text>
            <Text style={{ fontSize: 12, marginBottom: 2 }}>
              <Text style={{ fontWeight: "bold" }}>Independent legal representation (ILR) fees:</Text>{" "}
              {formatters.number.getCurrencyFromNumber(fees.ilr)}
            </Text>
            <Text style={{ fontSize: 12, marginBottom: 2 }}>
              <Text style={{ fontWeight: "bold" }}>Title insurance:</Text>{" "}
              {formatters.number.getCurrencyFromNumber(fees.titleInsurance)}
            </Text>
            {fees.inspection ? (
              <Text style={{ fontSize: 12, marginBottom: 2 }}>
                <Text style={{ fontWeight: "bold" }}>Inspection fee:</Text>{" "}
                {formatters.number.getCurrencyFromNumber(fees.inspection)}
              </Text>
            ) : null}
            <View style={tw("w-full flex flex-row justify-between")}>
              <Text style={{ fontSize: 12 }}>
                <Text style={{ fontWeight: "bold" }}>Appraisal fee:</Text>{" "}
                {formatters.number.getCurrencyFromNumber(fees.appraisal)}
              </Text>
              <Text style={{ fontSize: 12 }}>
                <Text style={{ fontWeight: "bold" }}>Total fees:</Text>{" "}
                {formatters.number.getCurrencyFromNumber(totalFees)}
              </Text>
            </View>
            <Text
              style={[
                tw(
                  "font-normal text-xs leading-normal text-gray-600 mb-4 pr-4 pl-0.5 mt-3 rounded-lg max-w-[60%]"
                ),
                { lineHeight: 1.1 },
              ]}
            >
              Note: these fees are provided as estimates. They may be different depending on property type,
              location, and other factors. *The APR is based on the interest rate, the origination fee, and
              the title insurance and conveyancing fees.
            </Text>
          </View>

          <Footer />
        </Page>
      </Document>
    );
  },
  _.isEqual
);

const LabeledBox = ({
  label,
  value,
  style,
}: { label?: string | ReactNode; value?: string | number | null | ReactNode; style?: Style | Style[] }) => {
  if (!value) {
    return null;
  }
  return (
    <View
      style={[
        {
          height: 70,
          width: 95,
          borderRadius: 5,
        },
        tw("p-2 border border-1 border-black flex flex-col items-center justify-center py-4"),
        style,
      ]}
    >
      <Text style={tw("text-xl leading-normal text-center font-bold")}>{value}</Text>
      <Text style={tw("text-sm text-center font-medium text-gray")}>{label}</Text>
    </View>
  );
};
//
// const LabeledValue = ({
//   label,
//   value,
//   style,
// }: { label?: string; value?: string | number | null; style?: Style | Style[] }) => {
//   if (!value) {
//     return null;
//   }
//   return (
//     <View style={[tw("flex basis-1/3 flex-row items-center mr-20 mb-2"), style]}>
//       <Text style={tw("flex-1 flex-initial text-base font-bold mr-2 truncate")}>{label}:</Text>
//       <Text style={tw("flex-1 flex-initial text-base font-medium")}>{value}</Text>
//     </View>
//   );
// };
