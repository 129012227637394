import { DocumentType, entities, enums, types } from "@fraction/shared";
import { addDays, isSameYear, subMonths } from "date-fns";
import _ from "lodash";
import { CircleCheckIcon, CircleHelpIcon, CircleXIcon, Loader } from "lucide-react";
import { memo } from "react";
import fraction from "src/api/fraction";
import { DashboardPage, Tooltip } from "src/components";
import { DocumentChecklistRow } from "src/components/DocumentChecklistRow";
import { InsurancePoliciesBlock } from "src/components/InsurancePolicies";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { Checkbox } from "src/components/ui/checkbox";
import { useCachedQueryClient } from "src/hooks/useCache";
import { useChecklist } from "src/hooks/useChecklist";
import { useMutation } from "src/lib";
import { selectInsurancePolicyStanding } from "src/selectors/insurancePolicies";
import Skeleton from "../../../components/Skeleton";

import { useLoanQuery } from "../queries";

const Documents = () => {
  useDocumentTitle("Property taxes & insurance | Fraction");
  const queryClient = useCachedQueryClient();

  const { data: loan } = useLoanQuery({
    refetchOnMount: false, // we only need the application id, so no need to refetch
    initialRefetch: true,
  });

  const onToggleDeferringPropertyTaxes = useMutation({
    mutationFn: async () => {
      const appId = loan?.applicationId;
      if (!appId) {
        return;
      }

      const newApp = (await queryClient.setQueryData(
        ["application", appId],
        // @ts-ignore
        (prev: entities.ApplicationT | undefined) => {
          if (!prev) {
            return prev;
          }
          prev.deferringPropertyTaxes = !prev?.deferringPropertyTaxes;
          return prev;
        }
      )) as entities.ApplicationT;

      await fraction.updateApplication(
        { id: appId },
        {
          deferringPropertyTaxes: newApp?.deferringPropertyTaxes,
        }
      );
    },
  });

  const {
    data: checklist,
    refetch: refetchChecklist,
    isLoading: checklistLoading,
  } = useChecklist({
    id: loan?.applicationId,
    status: enums.LoanStatus.ACTIVE,
  });

  const docChecklist = _.sortBy(
    checklist?.filter(types.isDocumentChecklistItem).filter(
      (item) =>
        item.document === DocumentType.PROPERTY_TAX_PAYMENT_CONFIRMATION &&
        item.year &&
        // property taxes are due around August/September-ish, so we want the previous year's property tax
        // confirmation up to around September, then we swap over to wanting the current year's taxes
        isSameYear(addDays(new Date(item.year), 5), subMonths(new Date(), 6))
    ),
    (d) => d.year && new Date(d.year)
  );
  const insurancePolicyStanding = selectInsurancePolicyStanding(loan);

  return (
    <DashboardPage
      heading="Property taxes and insurance"
      subHeading={
        <p>
          Manage your property taxes and insurance policies.{" "}
          <b className="font-bold">You must keep these details up to date</b> in order to keep your loan in
          good standing.
        </p>
      }
    >
      <div className="mt-2 max-w-3xl">
        <div className="flex flex-row items-center gap-2">
          <p className="text-2xl font-semibold mb-1">Insurance policies</p>
          {insurancePolicyStanding === "to-update" ? (
            <PolicyNotInGoodStanding />
          ) : insurancePolicyStanding === "approved" ? (
            <PolicyInGoodStanding />
          ) : (
            <PolicyToBeReviewed />
          )}
        </div>
        <p className="text-sm mb-2">
          In addition to it being a good idea to have property insurance, it is{" "}
          <Tooltip white text={<PropertyInsuranceDetailsText />}>
            <b className="cursor-help">part of your agreement with Fraction</b>
          </Tooltip>{" "}
          that you have property insurance and have us listed as a loss payee.
        </p>
        <InsurancePoliciesBlock hideTitle applicationId={loan?.applicationId} />
        <div className="flex flex-row items-center gap-2 mt-4">
          <p className="text-2xl font-semibold mb-1">Property taxes</p>
        </div>
        <p className="text-sm mb-2">
          Please ensure that you are up to date on your property taxes. Once you've paid your property taxes,
          please upload your property tax payment confirmation here.
          <br />
          <br /> It is a requirement of your loan agreement with Fraction that you keep your property taxes in
          good standing. If you have{" "}
          <Tooltip white text={<PropertyTaxDeferralTooltipText />}>
            <b className="cursor-help">deferred your property taxes</b>
          </Tooltip>
          , please let us know.
        </p>
        <Tooltip white text={<PropertyTaxDeferralTooltipText />}>
          <div className="items-top flex space-x-2 my-4">
            <Checkbox
              checked={loan?.application?.deferringPropertyTaxes}
              onClick={() => onToggleDeferringPropertyTaxes.mutateAsync()}
              id="propertyTaxesDeferred"
            />
            <label
              htmlFor="propertyTaxesDeferred"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              I have deferred my property taxes{" "}
              {onToggleDeferringPropertyTaxes.isPending ? (
                <Loader height={14} className="text-gray-600 animate-spin inline" />
              ) : (
                ""
              )}
            </label>
          </div>
        </Tooltip>
        <div>
          {!docChecklist?.length && checklistLoading
            ? Array.from({ length: 6 }).map((_, i) => <Skeleton className="w-full h-11" key={i} />)
            : null}
          {docChecklist?.map((item, idx) => (
            <DocumentChecklistRow
              onUploadSuccess={refetchChecklist}
              applicationId={loan?.applicationId}
              key={idx}
              item={item}
              allDebts={[]}
              canUpload
            />
          ))}
        </div>
      </div>
    </DashboardPage>
  );
};

const PropertyTaxDeferralTooltipText = () => (
  <p>
    Not sure whether you are eligible to defer your property taxes? Check with your local tax authority. See
    more details for{" "}
    <a
      target="_blank"
      className="font-semibold text-green"
      href="https://www2.gov.bc.ca/gov/content/taxes/property-taxes/annual-property-tax/property-tax-deferment-program"
    >
      BC homeowners
    </a>
    ,{" "}
    <a
      target="_blank"
      className="font-semibold text-green"
      href="https://www.ontario.ca/document/provincial-land-tax/provincial-land-tax-deferral-program-low-income-seniors-and-low-income"
    >
      Ontario homeowners
    </a>
    , or{" "}
    <a
      target="_blank"
      className="font-semibold text-green"
      href="https://www.alberta.ca/seniors-property-tax-deferral-program"
    >
      Alberta homeowners
    </a>
    .
  </p>
);

const PolicyInGoodStanding = () => (
  <div className="px-2 pr-3 py-0.5 flex flex-row items-center gap-1 bg-green-100 rounded-full">
    <CircleCheckIcon className="fill-green text-green-100 w-4 m-0" />
    <span className="font-normal text-green text-[10px]">Policy details are looking good!</span>
  </div>
);

const PropertyInsuranceDetailsText = () => (
  <p>
    Per Section 4.02 D of your Loan Agreement, we need to be listed as the First Loss Payee on your home
    insurance. We should be listed as the following:
    <br />
    <br />
    <b>Computershare Trust Company of Canada, 333 Seymour Street, Suite 8-104, Vancouver, BC, V6B 5A6</b>
  </p>
);

const PropertyInsuranceTooltipText = () => (
  <p>
    Please upload and/or update your property insurance policy details. If your insurance has expired, you
    must acquire new property insurance.
    <br />
    <br />
    <PropertyInsuranceDetailsText />
  </p>
);

const PolicyNotInGoodStanding = () => (
  <Tooltip white text={<PropertyInsuranceTooltipText />}>
    <div className="px-2 pr-3 py-0.5 flex flex-row items-center gap-1 bg-red-100 rounded-full cursor-help">
      <CircleXIcon className="fill-red text-red-100 w-4 m-0" />
      <span className="font-normal text-red text-[10px]">Policy details need updating</span>
    </div>
  </Tooltip>
);

const PolicyToBeReviewed = () => (
  <Tooltip
    white
    text="Hang tight! Fraction's team will review your policy information and get back to you if there are any issues."
  >
    <div className="px-2 pr-3 py-0.5 flex flex-row items-center gap-1 bg-yellow-100 rounded-full cursor-help">
      <CircleHelpIcon className="fill-yellow text-yellow-100 w-4 m-0" />
      <span className="font-normal text-yellow-800 text-[10px]">Policy details to be reviewed</span>
    </div>
  </Tooltip>
);

export default memo(Documents);
