import { validate } from "class-validator";
import config from "src/config";
import { Logger } from "src/log";
import { selectApplication, selectChecklistApplication } from "src/selectors";
import { recreateErrorObject } from "src/utilities/errors";
import { getCookie, getStoredCookies, saveCookie } from "src/utilities/tracking";
import { Request, Response, SuperAgentStatic } from "superagent";
import { z } from "zod";

import {
  ApplicationTaskType,
  ChecklistType,
  IncorrectInputsError,
  S3Bucket,
  Saved,
  SyntheticApplicationStatus,
  ValueError,
  engine,
  entities,
  enums,
  formatters,
  getSuperAgent,
  parsers,
  plainToInstance,
  schemas,
  selectors,
  toJSConvert,
  transformAndValidate,
  types,
  utilities,
} from "@fraction/shared";

const LOGGER = new Logger("api.fraction");

export type ChecklistApp = Saved<entities.ApplicationT> & {
  checklists: Partial<Record<SyntheticApplicationStatus | enums.LoanStatus, types.ChecklistResult[]>>;
  syntheticStatus: SyntheticApplicationStatus;
};

class FractionClient {
  private api: SuperAgentStatic & Request;
  private _baseURL: string;
  // you'd think we could access headers from superagent, but nope.
  private headers: Record<string, string> = {};

  public constructor() {
    this.api = getSuperAgent({
      basePath: config.baseURL,
      logger: LOGGER,
      timeout: { response: 27500, deadline: 27500 },
      retries: 0, // react-query handles retries
    });

    // recreate the error object when possible
    this.api.use((req) => {
      const end = req.end;
      req.end = function (callback) {
        return end.call(this, (error, response) => {
          callback?.(recreateErrorObject(error), response);
        });
      };
    });

    this._baseURL = config.baseURL;
  }

  public baseURL = (): string => this._baseURL;
  public authenticated: boolean = false;
  public token?: string;

  public setDefaultHeader = (header: string, value: string) => {
    this.api.use((req) => {
      req.set({ [header]: value });
      this.headers[header] = value;
      return req;
    });
  };

  public setBearerToken = (token: string) => {
    try {
      const user = utilities.auth.decodeToken(token);
      z.string().uuid().parse(user.id);
      z.string().email().parse(user.email);
      this.authenticated = true;
      this.setDefaultHeader("Authorization", `Bearer ${token}`);
      this.token = token;
    } catch (err) {
      if (err instanceof Error) {
        LOGGER.exception(err, `Attempting to set invalid user token ${token}`);
      }
    }
  };

  public clearBearerToken = () => {
    this.removeDefaultHeader("Authorization");
    this.authenticated = false;
    this.token = undefined;
  };

  public setHeaderSecret = (secret: string) => {
    this.setDefaultHeader("fraction-secret", secret);
  };

  public removeDefaultHeader = (header: string) => {
    this.api.use((req) => {
      req.unset(header);
      delete this.headers[header];
      return req;
    });
  };

  private setBearerTokenFromCookie = () => {
    const token = getCookie("fraction_auth_token");
    if (token) {
      this.setBearerToken(token);
    }
  };

  private prepBearerToken = () => {
    const existingToken = this.headers.Authorization;
    if (!existingToken) {
      this.setBearerTokenFromCookie();
    }
  };

  private handleAPIError = (err: Error, res: Response, retry: () => void) => {
    if (!res.status) {
      return;
    }

    switch (res.status) {
      case 401: {
        LOGGER.warn("Received a 401. Bearer token likely unset.");
        // Occasionally, the bearer token gets unset. TODO: This is a hacky workaround - we should
        // figure out how the bearer token gets unset and resolve that. In the meantime, we will grab
        // the stored auth token (if it exists) from cookies
        const token = getCookie("fraction_auth_token");
        if (token) {
          this.setBearerToken(token);
          // if there was a 401 request, we will try to reset the auth with cookies, then we will
          // retry here. You'd think that letting superagent retry another time would be sufficient,
          // but the retried message doesn't contain any modifications to headers, so we have to make a
          // totally new request.
          retry();
        }
      }
    }
  };

  private setAuthorizationFromReturnHeaders = (response: Response) => {
    const token = response.headers["x-authorization"];
    // just doing 20 because JWTs are always long and if "undefined" got stringified or something
    // we are still safe
    if (response.status < 300 && token?.length > 20) {
      this.setBearerToken(token);
      saveCookie("fraction_auth_token", token);
    } else {
      LOGGER.log(
        `Response does not contain proper x-authorization header; it is ${token?.substring(0, 10)}, len ${
          token?.length
        }`
      );
    }
  };

  private getCookieFilledRequest(request: Request) {
    this.prepBearerToken();

    const cookies = getStoredCookies();
    LOGGER.log(`Stored cookies to be loaded into HTTP request: ${JSON.stringify(cookies)}`);

    if (cookies) {
      request.set("fraction-cookies", JSON.stringify(cookies));
    }

    if (!this.headers.Authorization) {
      const cookie: string | undefined = getCookie("fraction_auth_token");
      LOGGER.log(
        `Auth header is missing in request; cookie is ${cookie?.substring(0, 10)}, len ${cookie?.length}`
      );
    }

    return request;
  }

  private getIdempotentRequest(request: Request, idempotencyKey: string) {
    if (!idempotencyKey) {
      LOGGER.warn("Idempotency key is missing");
    }
    return request.set("idempotency-key", idempotencyKey);
  }

  private submitApplication = async (
    loanApplication: parsers.application.FullApplication,
    type: "partial" | "full" | "self",
    retry: number = 1
  ): Promise<schemas.Application> => {
    const groupLogger = LOGGER.createGroupLogger();
    groupLogger.log(`Submit request for ${type} application ${loanApplication.id} started`);
    const response = await this.getCookieFilledRequest(this.api.post(`/application/${type}`))
      .send(loanApplication)
      .retry(retry, (err, res) => {
        if (err) {
          groupLogger.warn(
            `Submit request for ${type} application ${loanApplication.id} failed: ${err?.message || err}`
          );
          this.handleAPIError(err, res, () => this.submitApplication(loanApplication, type, retry - 1));
        }
      });
    groupLogger.log(`Submit request for ${type} application ${loanApplication.id} completed`);
    this.setAuthorizationFromReturnHeaders(response);
    return transformAndValidate(schemas.Application, response.body as object);
  };

  public submitPartialApplication = async (
    loanApplication: parsers.application.FullApplication
  ): Promise<schemas.Application> => this.submitApplication(loanApplication, "partial", 0);

  public submitFullApplication = async (
    loanApplication: parsers.application.FullApplication
  ): Promise<schemas.Application> => this.submitApplication(loanApplication, "full");

  public selfSubmitApplication = async (
    loanApplication: parsers.application.Application
  ): Promise<schemas.Application> => this.submitApplication(loanApplication, "self");

  public submitInquiry = async (inquiry: parsers.inquiry.Inquiry): Promise<entities.Application> => {
    const response = await this.getCookieFilledRequest(this.api.post("/application/inquiry")).send(inquiry);
    return selectApplication(await transformAndValidate(schemas.Application, response.body as object));
  };

  public addToWaitlist = async (waitlist: parsers.inquiry.Inquiry): Promise<Response> =>
    this.getCookieFilledRequest(this.api.post("/application/waitlist")).send(waitlist);

  public async saveApplication(loanApplication: entities.Application): Promise<entities.Application> {
    const translated = await transformAndValidate(entities.Application, loanApplication);
    const response = await this.api.post("/application").send(toJSConvert(entities.Application, translated));
    return selectApplication(await transformAndValidate(schemas.Application, response.body as object));
  }

  public async deleteApplications(loanApplications: string[]) {
    await this.api.delete("/application").send(loanApplications);
  }

  public async login(user: entities.User): Promise<string> {
    await validate(user);
    const { email, password } = user;
    const response = await this.api.get("/user/login").query({ email, password });
    return response.body.token;
  }

  public async create(user: entities.User): Promise<string> {
    await validate(user);
    const response = await this.api
      .post("/user")
      .send(toJSConvert(entities.User, user, { groups: ["admin"] }));
    return response.body.token;
  }

  public async getUser(token?: string): Promise<entities.User> {
    const query: any = {};
    if (token) {
      query.token = token;
    }
    const response = await this.api.get("/user").query(query);
    return response.body;
  }

  public async updateUser(user: Pick<entities.User, "firstName" | "lastName">): Promise<entities.User> {
    const response = await this.api.put("/user").send({
      firstName: user.firstName,
      lastName: user.lastName,
    });
    return response.body;
  }

  public getUserBrokerage = async (): Promise<entities.BrokerGroupMember> => {
    const response = await this.api.get("/user/brokerage");
    return response.body;
  };

  public async getUserFromID(id: string): Promise<entities.User> {
    const response = await this.api.get(`/user/${id}`);
    return transformAndValidate(entities.User, response.body as object);
  }

  public async resetUserPasswordRequest(email: string): Promise<void> {
    const response = await this.api.post("/user/passwordResetRequest").send({ email });
    return response.body;
  }

  public async resetUserPassword(password: string): Promise<entities.User> {
    const response = await this.api.post("/user/passwordReset").send({ password });
    return transformAndValidate(entities.User, response.body as object);
  }

  public async registerUserPassword(password: string): Promise<entities.User> {
    const response = await this.api.post("/user/register-password").send({ password });
    return transformAndValidate(entities.User, response.body as object);
  }

  public async requestVerificationEmail({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<void> {
    await this.api.post("/user/verify").send({ email, password });
  }

  public async verifyEmail(token: string): Promise<void> {
    await this.api.get("/user/verify").query({ token });
  }

  public getApplication = async (
    id: string,
    options?: { userType?: "employee" | "broker" | "applicant" | "conveyancer" | "lender" }
  ): Promise<ChecklistApp> => {
    const response = await this.api.get(`/application/${id}`).query(options || {});

    const app: any = plainToInstance(entities.Application, response.body);
    app.checklists = response.body.checklists;
    app.syntheticStatus = selectors.application.selectSyntheticApplicationStatus(response.body);
    return app;
  };

  public getPropertyValuation = async (
    location: types.ExtractedPropertyAddress | string
  ): Promise<{ value: number }> => {
    const response = await this.api
      .get("/ops/property/avm")
      .query(typeof location === "string" ? { q: location } : location);
    return response.body;
  };

  public underwriteProperty = async (
    location: types.ExtractedPropertyAddress | string
  ): Promise<(engine.EngineRuleProcessorResult & { name: enums.UnderwritingRuleName })[]> => {
    const response = await this.api
      .get("/ops/property/underwrite")
      .query(typeof location === "string" ? { q: location } : location);
    return response.body;
  };

  public getPropertyReport = async (location: types.ExtractedPropertyAddress): Promise<entities.Property> => {
    const response = await this.api.get("/ops/property/report").query(location);
    return response.body;
  };

  public async searchApplications(q: string): Promise<entities.ApplicationT[]> {
    const response = await this.api.get("/application/search").query({ q });
    return plainToInstance(entities.Application, response.body as object[]) || [];
  }

  public getApplications = async (
    {
      accountType,
      status,
      offset,
      limit,
      since,
    }: {
      accountType?: "employee" | "broker" | "applicant" | "conveyancer" | "lender";
      status?: "active" | "all" | "closed" | "realization" | "funded";
      offset?: number;
      limit?: number;
      since?: number;
    } = {},
    options?: types.RequestOptions
  ): Promise<types.ArrayResponse<ChecklistApp>> => {
    const response = await this.api
      .get("/application")
      .timeout(30000)
      .query({
        options: formatters.querystring.stringify(options),
        accountType,
        status,
        offset,
        limit,
        since,
      });
    const data = response.body.data;
    return {
      count: response.body.count,
      pagination: response.body.pagination,
      data: data.map(selectChecklistApplication),
    };
  };

  public async updateUploadedFile(file: entities.UploadedFile): Promise<entities.UploadedFile> {
    await validate(file);
    const response = await this.api.put("/document/file").send(toJSConvert(entities.UploadedFile, file));
    return transformAndValidate(entities.UploadedFile, response.body as object);
  }

  public async getDocumentDetailsWithUrl(args: {
    fileId: string;
  }): Promise<Partial<entities.UploadedFile> & { fileURL: string }> {
    const response = await this.api.get("/document/file/fetch-details").query(args);
    return response.body;
  }

  public async setDocumentStatusAndNotes({
    id,
    ...args
  }: {
    status: enums.ApplicationTaskApprovalStatus;
    notes?: string;
    id: string;
  }): Promise<Partial<entities.UploadedFile> & { fileURL: string }> {
    const response = await this.api.patch(`/document/file/approval/${id}`).send(args);
    return response.body;
  }

  public async getPresignedDownloadParams(
    args: { filename: string; applicationID: string } | { fileId: string }
  ): Promise<{ fileURL: string }> {
    const response = await this.api.get("/document/file/fetch").query(args);
    return response.body;
  }

  public async getPresignedFileDownloadUrl(uploadedFileId: string): Promise<string> {
    const response = await this.api.get("/document/file/fetch").query({ uploadedFileId });
    return response.body.fileURL;
  }

  public async deleteFile(id: string, opts: { force?: boolean } = {}): Promise<void> {
    await this.api.delete(`/document/file/${id}`).query(opts);
  }

  public async getBrokerGroup(id: string, options?: types.RequestOptions): Promise<entities.BrokerGroup> {
    const response = await this.api
      .get(`/broker/group/${id}`)
      .query({ options: formatters.querystring.stringify(options) });
    return transformAndValidate(entities.BrokerGroup, response.body as object);
  }

  public async getBrokerGroupRequesters(
    id: string,
    options?: types.RequestOptions
  ): Promise<types.ArrayResponse<entities.BrokerGroupMember>> {
    const response = await this.api
      .get(`/broker/group/${id}/requesters`)
      .query({ options: formatters.querystring.stringify(options) });
    return selectors.query.parsePaginatedResponse(entities.BrokerGroupMember, response.body);
  }

  public async getBrokerGroupMembers(
    id: string,
    options?: types.RequestOptions
  ): Promise<types.ArrayResponse<entities.BrokerGroupMember>> {
    const response = await this.api
      .get(`/broker/group/${id}/members`)
      .query({ options: formatters.querystring.stringify(options) });
    return selectors.query.parsePaginatedResponse(entities.BrokerGroupMember, response.body);
  }

  public async adminRejectBrokerGroupMembershipRequest(groupID: string, memberID: string): Promise<void> {
    const response = await this.api.post(`/broker/group/${groupID}/member/${memberID}`).send({
      status: enums.GroupMemberStatus.REJECTED_BY_ADMIN,
    });
    return response.body;
  }

  public async adminAcceptBrokerGroupMembershipRequest(groupID: string, memberID: string): Promise<void> {
    const response = await this.api.post(`/broker/group/${groupID}/member/${memberID}`).send({
      status: enums.GroupMemberStatus.ACTIVE,
    });
    return response.body;
  }

  public async inviteToBrokerGroup(
    groupID: string,
    invitee: { lastName: string; firstName: string; email: string }
  ): Promise<void> {
    const response = await this.api.post("/broker/group/invite").send({ groupID, invitee });
    return response.body;
  }

  public async sendInviteToApplicant({
    inviteeId,
    channels,
  }: Partial<entities.Invite> & { channels: enums.ChannelType[] }): Promise<entities.Invite> {
    const response = await this.api
      .post("/user/invite")
      .send({ inviteeId, type: enums.InviteType.BROKER_TO_APPLICANT, channels });
    return plainToInstance(entities.Invite, response.body as object);
  }

  public async getInvites(): Promise<entities.Invite[]> {
    const response = await this.api.get("/user/invite");
    return plainToInstance(entities.Invite, response.body as object[]);
  }

  public async getInvite(id: string): Promise<entities.Invite> {
    const response = await this.api.get(`/user/invite/${id}`);
    return plainToInstance(entities.Invite, response.body as object);
  }

  public async acceptInvite(inviteId: string): Promise<void> {
    await this.api.post(`/user/invite/${inviteId}/accept`);
  }

  public async rejectInvite(inviteId: string): Promise<void> {
    await this.api.post(`/user/invite/${inviteId}/reject`);
  }

  public async userAcceptBrokerGroupInvite(groupID: string): Promise<void> {
    const response = await this.api
      .post(`/broker/group/${groupID}/member`)
      .send({ status: enums.GroupMemberStatus.ACTIVE });
    return response.body;
  }

  public async userRejectBrokerGroupInvite(groupID: string): Promise<void> {
    const response = await this.api
      .post(`/broker/group/${groupID}/member`)
      .send({ status: enums.GroupMemberStatus.REJECTED_BY_SELF });
    return response.body;
  }

  public async requestToJoinBrokerGroup(groupID: string): Promise<void> {
    const response = await this.api
      .post(`/broker/group/${groupID}/member`)
      .send({ status: enums.GroupMemberStatus.REQUESTED });
    return response.body;
  }

  public getZHVI = async (zip: string): Promise<entities.HomeValuationIndex | undefined> => {
    try {
      const response = await this.api.get("/admin/data/zhvi").query({ zip });
      if (response.status >= 500) {
        LOGGER.warn("Internal error. Unable to get ZHVI", {
          body: response.body,
          status: response.status,
        });
      }
      return response.body;
    } catch (err) {
      if (err?.response?.status && err.response.status >= 500) {
        LOGGER.exception(err, "Internal error. Unable to get ZHVI", {
          body: err.response.body,
          status: err.response.status,
        });
      }
    }
  };

  public async getMonthlyStatement(id: string): Promise<types.FileURL> {
    const response = await this.api.get(`/document/file/monthlyStatement/${id}`);

    return response.body;
  }

  public async validateEmail(email: string) {
    return this.api.get("/user/validate/email").query({ email }).timeout(20000).retry(1);
  }

  public async getTransactionRecords(
    loanId: string,
    {
      transactionTypes,
      monthlyAggregatedTypes,
    }: {
      transactionTypes?: enums.TransactionType[];
      monthlyAggregatedTypes?: enums.TransactionType[];
    }
  ): Promise<parsers.transactionRecord.FetchTransactionRecordsResponse> {
    const response = await this.api.get(`/bank/transaction/loan/${loanId}`).query({
      types: JSON.stringify(transactionTypes),
      monthlyAggregatedTypes: JSON.stringify(monthlyAggregatedTypes),
    });

    return parsers.transactionRecord.parseFetchTransactionRecordsResponse(response.body);
  }

  public async getRates(options: parsers.rate.FetchRateArgs): Promise<parsers.rate.Rates> {
    const optionsSerialized = {
      ...options,
      regions: JSON.stringify(options.regions),
    };
    const response = await this.api.get("/admin/rate").query(optionsSerialized).retry(1);
    return parsers.rate.parseRates(response.body);
  }

  public async getBankAccounts(): Promise<entities.BankAccount[]> {
    const response = await this.api.get("/bank/account");
    return plainToInstance(entities.BankAccount, response.body.data);
  }

  public async createBankAccount(body: parsers.bankAccount.BankAccountSubmission): Promise<Response> {
    return this.api.post("/bank/account").send(body);
  }

  public async verifyBankAccount(
    bankAccountId: string,
    amounts: parsers.modernTreasury.Verification
  ): Promise<Response> {
    return this.api.post(`/bank/account/${bankAccountId}/verify`).send(amounts);
  }

  public async deleteBankAccount(bankAccountId: string): Promise<Response> {
    return this.api.delete(`/bank/account/${bankAccountId}`);
  }

  public async editBankAccount(
    bankAccountId: string,
    body: parsers.bankAccount.PatchBankAccountSubmission
  ): Promise<Response> {
    return this.api.patch(`/bank/account/${bankAccountId}`).send(body);
  }

  public async getPostalScore(postalCode: string): Promise<{
    postalScoreCheckStatus: enums.EngineRuleStatus;
    bdm?: {
      email: string;
      firstName: string;
      lastName: string;
      title?: string;
      photoLink?: string;
      phone: string;
      calendarLink?: string;
    };
  }> {
    const response = await this.api.get("/application/postal-score").query({ postalCode }).retry(1);
    return response.body;
  }

  public async searchForBrokerage(
    brokerageString: string
  ): Promise<{ id: string; name: string; tradeName?: string; officeLocation?: entities.OfficeLocation }[]> {
    const response = await this.api.get("/broker/group/search").query({ q: brokerageString }).retry(1);
    return response.body;
  }

  public async getBdmForBrokerage(id: string): Promise<
    | {
        email: string;
        firstName: string;
        lastName: string;
        title?: string;
        photoLink?: string;
        phone: string;
        calendarLink?: string;
      }
    | undefined
  > {
    const response = await this.api.get(`/broker/group/${id}/bdm`).retry(1);
    return response.body;
  }

  public async createPaymentOrder(
    body: parsers.payment.PaymentOrder,
    idempotencyKey: string
  ): Promise<Response> {
    return this.getIdempotentRequest(this.api.post("/bank/payments").send(body).retry(0), idempotencyKey);
  }

  public async getPaymentSubscriptions(
    loanId: string
  ): Promise<parsers.paymentSubscription.UsablePaymentSubscription[]> {
    const response = await this.api.get(`/bank/paymentSubscription/loan/${loanId}`);
    return parsers.paymentSubscription.parseUsablePaymentSubscriptions(response.body.data);
  }

  public async createPaymentSubscription(
    body: parsers.paymentSubscription.PaymentSubscriptionBody
  ): Promise<Response> {
    return this.api.post("/bank/paymentSubscription").send(body);
  }

  public async deletePaymentSubscription(id: string): Promise<Response> {
    return this.api.delete(`/bank/paymentSubscription/${id}`);
  }

  public async getApplicationDocuments(
    applicationId: string,
    { status }: { status?: enums.ApplicationTaskApprovalStatus }
  ): Promise<Saved<entities.UploadedFile>[]> {
    const response = await this.api.get(`/document/file/application/${applicationId}`).query({ status });
    return plainToInstance(
      entities.UploadedFile,
      response.body.data as object[]
    ) as Saved<entities.UploadedFile>[];
  }

  public async getApplicationLoanPackage(applicationId: string): Promise<types.FileURL> {
    const response = await this.api.get(`/document/file/application/${applicationId}/zip`);
    return response.body;
  }

  public async getApplicationDocumentUrl(id: string): Promise<types.FileURL> {
    const response = await this.api.get(`/document/file/${id}/url`);
    return response.body;
  }

  /*
   * Admin authenticated requests (with fraction-secret header)
   */

  public async getDocumentTypes(): Promise<parsers.documentType.DocumentTypes> {
    const response = await this.api.get("/document/documentType");
    return parsers.documentType.parseDocumentTypes(response.body);
  }

  public async deprecateDocumentType(id: string, deprecated: boolean): Promise<void> {
    await this.api.patch(`/document/documentType/${id}`).send({ deprecated });
  }

  public async createDocumentType(
    body: parsers.documentType.DocumentTypeNoId
  ): Promise<parsers.documentType.DocumentType> {
    const response = await this.api.post("/document/documentType").send(body);
    return parsers.documentType.parseDocumentType(response.body);
  }

  public async updateDocumentType(
    body: parsers.documentType.DocumentType
  ): Promise<parsers.documentType.DocumentType> {
    const response = await this.api.patch(`/document/documentType/${body.id}`).send(body);
    return parsers.documentType.parseDocumentType(response.body);
  }

  public async createLabeledDocumentAndStartSplitJob(
    labeledDocument: parsers.documentSplit.CreateLabeledDocumentArgs
  ): Promise<
    | parsers.asyncJob.AsyncJobCreationLabeledDocumentHandlerResponse
    | { uploadedFile: entities.UploadedFile; presignedUrl: string }
  > {
    const response = await this.api
      .post("/document/label")
      .send({ ...labeledDocument, async: false })
      .timeout(35000);
    return response.body;
  }

  public async getPresignedAdminFileUploadUrl(bucket: S3Bucket, key: string): Promise<string> {
    const response = await this.api.get("/document/file/admin/create").query({ key, bucket });
    return response.body.fileURL;
  }

  public async uploadFile(file: File, url: string, contentType?: string): Promise<void> {
    await fetch(url, {
      method: "PUT",
      body: file,
      headers: contentType ? { "Content-Type": contentType } : {},
    });
    // let req = this.api.put(url).send(file);
    // if (contentType) {
    //   req = req.set("Content-Type", contentType);
    // }
    // await (req);
  }

  public async createAdminUploadedFile({
    s3Bucket,
    s3Key,
    applicationId,
    fileType,
    status,
    notes,
  }: {
    s3Bucket: S3Bucket;
    s3Key: string;
    applicationId: string;
    fileType?: string;
    status?: enums.ApplicationTaskApprovalStatus;
    notes?: string;
  }): Promise<Saved<entities.UploadedFile> & { pages: Saved<entities.UploadedFilePage>[] }> {
    const response = await this.api.post("/document/file/admin").send({
      applicationId,
      s3Bucket,
      s3Key,
      fileType,
      status,
      notes,
    });
    return transformAndValidate(entities.UploadedFile, response.body as object) as Promise<
      Saved<entities.UploadedFile> & {
        pages: Saved<entities.UploadedFilePage>[];
      }
    >;
  }

  public async createDocumentSplitJob(
    classificationId: string
  ): Promise<parsers.asyncJob.AsyncJobCreationHandlerResponse> {
    const response = await this.api
      .post("/document/documentSplit/admin/splitFromClassification")
      .send({ classificationId });

    return parsers.asyncJob.parseAsyncJobCreationHandlerResponse(response.body);
  }

  public async getDocumentSplitAsyncJob(jobId?: string): Promise<entities.AsyncDocumentSplitJob> {
    const response = await this.api.get(`/admin/asyncJob/${jobId}`);
    return transformAndValidate(entities.AsyncDocumentSplitJob, response.body as object);
  }

  public async initiateIDVerification({
    applicantId,
  }: InitiateIDVerificationArgs): Promise<types.IdVerificationReturnType> {
    const response = await this.api.post(`/application/applicant/${applicantId}/idVerification`).send({});
    if (!types.isIdVerificationReturn(response.body)) {
      throw new ValueError(
        `/application/applicant/${applicantId}/idVerification returned an incorrect id verification response`
      );
    }
    return response.body;
  }

  public async continueIDVerification({
    applicantId,
    answers,
    attemptId,
  }: AnswerIDVerificationArgs): Promise<types.IdVerificationReturnType> {
    const response = await this.api.post(`/application/applicant/${applicantId}/idVerification`).send({
      kiqAnswers: answers,
      attemptId,
    });
    return response.body as types.IdVerificationReturnType;
  }

  public async getTestApplication(type: string): Promise<entities.Application | undefined> {
    const response = await this.api.get(`/application/test/${type}`);
    if (response.statusCode !== 200) {
      return;
    }
    return transformAndValidate(entities.Application, response.body as object);
  }

  public async getChecklist({
    crmId,
    type,
  }: {
    crmId?: string;
    type?: ChecklistType;
  }): Promise<parsers.checklist.PerformedChecklist> {
    const response = await this.api.post("/ops/checklist").send({
      id: crmId,
      type,
    });
    return parsers.checklist.parsePerformedChecklist(response.body);
  }

  public async setApplicationStatus({
    id,
    status,
    force,
  }: {
    id: string;
    status: SyntheticApplicationStatus;
    force?: boolean;
  }): Promise<
    Record<
      "succeeded" | "failed",
      {
        failures?: types.ChecklistResult[];
        applicationId: string;
        notes?: string;
        status: enums.ApplicationStatus;
        syntheticStatus?: SyntheticApplicationStatus;
      }[]
    >
  > {
    const response = await this.api.patch("/application/status").send([
      {
        applicationId: id,
        status,
        force,
      },
    ]);
    return response.body;
  }

  public async getCrxData({ crmId, type }: { crmId?: string; type?: string }) {
    const response = await this.api.get(`/ops/crx/${crmId}/${type}`).send();
    return response.body;
  }

  public async getProtectedFileSignedUrl({
    password,
    token,
  }: {
    password: string;
    token: string;
  }): Promise<{ fileUrl: string; key: string }> {
    const response = await this.api
      .get("/document/file/protected-file")
      .set("Authorization", password)
      .query({ token });
    return response.body;
  }

  public async getCrxApplicants({ crmId }: { crmId?: string }): Promise<entities.Applicant[]> {
    const response = (await this.api.get(`/ops/crx/${crmId}/applicants`).send()) as {
      body: object[];
    };
    return Promise.all(
      response.body.map((body: object) => {
        return transformAndValidate(entities.Applicant, body as object);
      })
    );
  }

  public async getCrxLoan({ crmId }: { crmId?: string }): Promise<entities.Loan> {
    const response = (await this.api.get(`/ops/crx/${crmId}/loan`).send()) as {
      body: object[];
    };
    return transformAndValidate(entities.Loan, response.body as object);
  }

  public async getCrxApplication({ crmId }: { crmId?: string }): Promise<entities.Application> {
    const response = (await this.api.get(`/ops/crx/${crmId}/application`).send()) as {
      body: object[];
    };
    return transformAndValidate(entities.Application, response.body as object);
  }

  public async updateApplication(
    { id }: { id?: string },
    updates: Partial<
      Pick<
        entities.ApplicationT,
        | "requestedAmount"
        | "requestedCloseDate"
        | "noSpecificCloseDateRequired"
        | "exitPlanNotes"
        | "deferringPropertyTaxes"
      >
    >
  ): Promise<entities.ApplicationT> {
    const response = await this.api.patch(`/application/${id}`).send(updates);
    return plainToInstance(entities.Application, response.body as object);
  }

  public async updateProperty(
    { id }: { id?: string },
    updates: Partial<{ valuation: Partial<entities.PropertyValuation> }>
  ): Promise<entities.Property> {
    const response = await this.api.patch(`/ops/property/${id}`).send(updates);
    return plainToInstance(entities.Property, response.body as object);
  }

  public async upsertApplicationSolicitor(
    applicationId: string,
    updates: Partial<entities.Solicitor>
  ): Promise<entities.Solicitor> {
    const response = await this.api.put(`/application/${applicationId}/solicitor`).send(updates);
    return plainToInstance(entities.Solicitor, response.body as object);
  }

  /**
   * Can be crmId, eid, or app id
   */
  public async runUnderwriting(applicationId?: string): Promise<engine.RunUnderwritingResults> {
    if (!applicationId) {
      throw new IncorrectInputsError("Require an applicationId to run underwriting");
    }
    const response = (await this.api.post(`/application/${applicationId}/run-underwriting`).send()) as {
      body: engine.RunUnderwritingResults;
    };
    return response.body;
  }

  public async getPortfolioProperties(filters: PortfolioFilters = {}) {
    const response = await this.api
      .get("/admin/portfolio/analytics/properties")
      .query(constructPortfolioFilter(filters))
      .timeout(30000);
    return response.body;
  }

  public async getPortfolioDocuments(): Promise<Saved<entities.S3Document>[]> {
    const response = await this.api.get("/admin/portfolio/document").send();
    return response.body;
  }

  public async getPortfolioDocumentSignedUrl(s3DocumentId: string): Promise<types.FileURL> {
    const response = await this.api.get(`/admin/portfolio/document/${s3DocumentId}/url`).send();
    return response.body;
  }

  public async getPortfolioAnalytics() {
    const response = await this.api.get("/admin/portfolio/analytics").send();
    return response.body;
  }

  public async startPhoneVerification(phone: string): Promise<{ status: "pending" | "approved" }> {
    const response = await this.api.post("/user/verify/sms/start").send({ to: phone });
    return response.body;
  }

  public async checkPhoneVerification(
    phone: string,
    code: string
  ): Promise<{ status: "pending" | "approved" }> {
    const response = await this.api.post("/user/verify/sms/check").send({ to: phone, code });
    return response.body;
  }

  public async makeChannelPrimary(channelId: string): Promise<entities.Channel> {
    const response = await this.api.post(`/user/channel/${channelId}/make-primary`);
    return response.body;
  }

  public async deleteChannel(channelId: string): Promise<{ ok: boolean }> {
    const response = await this.api.delete(`/user/channel/${channelId}`);
    return response.body;
  }

  public async startEmailVerification(email: string): Promise<{ status: "pending" | "approved" }> {
    const response = await this.api.post("/user/verify/email/start").send({ to: email });
    return response.body;
  }

  public async checkEmailVerification(
    email: string,
    code: string
  ): Promise<{ status: "pending" | "approved" }> {
    const response = await this.api.post("/user/verify/email/check").send({ to: email, code });
    return response.body;
  }

  public async getPortfolioTypes(filters: PortfolioFilters = {}): Promise<{
    propertyTypes: (Record<string | "All", number> & {
      type: enums.PropertyType;
    })[];
    propertyMSAs: types.PropertyMsaRecord[];
    incomeSources: {
      type: enums.IncomeType;
      count: number;
      percentage: number;
    }[];
    applicantAges: parsers.portfolio.DataAndStats;
    loanSizes: parsers.portfolio.DataAndStats;
    creditScores: parsers.portfolio.ToggleableDataAndStats;
    loanToValue: {
      stats: { current: parsers.stats.Stats; atOrigination: parsers.stats.Stats };
      data: parsers.portfolio.LoanToValueData[];
    };
    portfolioSize: {
      fundings: parsers.portfolio.CountAndSumByQuarter[];
      total: parsers.portfolio.CountAndSumByQuarter[];
      payoffs: parsers.portfolio.CountAndSumByQuarter[];
    };
    loanToValueOverTime: GroupingLtvOverTimeData;
    propertyValueOverTime: GroupingPropertyValueOverTimeData;
    fundings: parsers.portfolio.CountAndSumByDay[];
    payouts: parsers.portfolio.Payout[];
    payoutGroupings: parsers.portfolio.PayoutGrouping[];
    useOfFunds: parsers.portfolio.UseOfFunds[];
    brokerVsDirect: { count: number; type: "broker" | "direct" }[];
    collateralGroupings: parsers.portfolio.PayoutGrouping[];
    weightedAverageCoupon: {
      withAppreciation: boolean;
      group: string;
      data: (parsers.stats.Stats & { year?: number; wac: number })[];
    }[];
    weightedAverageMaturity: {
      group: string;
      data: { year?: number; wam: number; wam_count: number; wa_termlength: number }[];
    }[];
    tdsAndGds: {
      tds: {
        pre: parsers.stats.Stats;
        post: parsers.stats.Stats;
      };
      gds: {
        pre: parsers.stats.Stats;
        post: parsers.stats.Stats;
      };
    };
    portfolioOverview: parsers.portfolio.Overview;
    projectedMaturities: { month: string; count: number; volume: number }[];
    creditScoreDelta: {
      data: parsers.stats.Stats & {
        data: { count: number; scoreDelta: number; percentage: number; label: string }[];
      };
    };
  }> {
    const response = await this.api
      .get("/admin/portfolio/analytics/types")
      .query(constructPortfolioFilter(filters))
      .timeout(30000);
    return response.body;
  }

  public async getPortfolioLoan(applicationId: string): Promise<types.PortfolioApp | undefined> {
    const response = await this.api.get(`/admin/portfolio/analytics/application/${applicationId}`).send();
    return response.body;
  }

  public async getPortfolioData(filters: PortfolioFilters = {}): Promise<entities.LoanT[]> {
    const response = await this.api
      .get("/admin/portfolio/analytics/application")
      .query(constructPortfolioFilter(filters))
      .send();

    return plainToInstance(entities.Loan, response.body);
  }

  public async createPrepayment(
    loanId: string,
    body: {
      type: types.PrepaymentType;
    }
  ): Promise<Response> {
    return this.api.post(`/bank/prepayment/${loanId}`).send(body);
  }

  public async createLoanDraw(loanId: string, body: parsers.loanDraw.LoanDraw): Promise<Response> {
    return this.api.post(`/bank/loanDraw/${loanId}`).send(body);
  }

  /**
   * Starting the bank account linking
   */
  public getPlaidLinkToken = async (purpose: "underwriting" | "payments"): Promise<string> => {
    const response = await this.api.post("/bank/link").send({ purpose });
    return response.body?.linkToken;
  };

  public registerPlaidBank = async (
    publicToken: string,
    applicationId: string
  ): Promise<{ linkToken: string; plaidToken: string }> => {
    const response = await this.api.post("/bank/link/register").send({
      publicToken,
      applicationId,
    });
    return response.body;
  };

  public getPlaidIncome = async (): Promise<any> => {
    const response = await this.api.get("/bank/income").send();
    return response.body?.income;
  };

  public getPlaidAssets = async (): Promise<any> => {
    const response = await this.api.get("/bank/assets").send();
    return response.body?.income;
  };

  public async getAppChecklist(
    appId: string,
    status: SyntheticApplicationStatus | enums.LoanStatus
  ): Promise<types.ChecklistResult[]> {
    const response = await this.api.get(`/application/${appId}/checklist/${status}`);

    return response.body;
  }

  public async initiateTask({
    applicantId,
    taskType,
    channels,
  }: { applicantId: string; taskType: ApplicationTaskType; channels: ("email" | "sms")[] }) {
    const response = await this.api
      .post(`/application/applicant/${applicantId}/${taskType}/initiate`)
      .send({ channels });
    return response.body;
  }

  public getComms = async ({
    offset,
    limit,
    app,
  }: {
    offset?: number;
    limit?: number;
    app?: string;
  } = {}): Promise<types.ArrayResponse<entities.Communication>> => {
    const response = await this.api.get("/comms").timeout(30000).query({
      offset,
      limit,
      app,
    });
    const data = response.body.data;
    return {
      count: response.body.count,
      data: plainToInstance(entities.Communication, data),
    };
  };

  public getComm = async (id: string): Promise<entities.Communication> => {
    const response = await this.api.get(`/comms/${id}`).timeout(30000);

    return plainToInstance(entities.Communication, response.body) as entities.Communication;
  };

  /**
   * This is a three-step process.
   * 1. The client gets N number of pre-signed URLs from the backend to upload the file(s) to S3 from GET /document/file/create/combinable
   * 2. The client uploads the file(s) to S3 directly
   * 3. The client sends the details of the file(s) to POST /document/file/register/combinable to combine the files and create an UploadedFile entity
   */
  public async getPresignedFileUploadUrls({
    applicationId,
    numberOfFiles,
  }: { applicationId: string; numberOfFiles: number }): Promise<types.FileURL[]> {
    const response = await this.api
      .get("/document/file/create/combinable")
      .query({ applicationId, numberOfFiles });
    return response.body;
  }

  public async registerUploadedFilesToApplication(
    args: parsers.file.RegisterDocumentArgs
  ): Promise<Saved<entities.UploadedFile>> {
    const response = await this.api.post("/document/file/register/combinable").send(args);
    return plainToInstance(entities.UploadedFile, response.body as object) as Saved<entities.UploadedFile>;
  }

  public previewDocumentDownloadUrl(documentType: parsers.docGen.DocGenDocumentType): string {
    return `${this._baseURL}/document/generate/${documentType}/preview-test`;
  }

  public async getFractionContacts(): Promise<entities.InternalEmployee[]> {
    const response = await this.api.get("/broker/contacts");
    return plainToInstance(entities.InternalEmployee, response.body);
  }

  public async getEmployees(types: enums.InternalEmployeeType[]): Promise<entities.InternalEmployee[]> {
    const response = await this.api.get("/ops/contact/employees").query({ types: types.join(",") });
    return plainToInstance(entities.InternalEmployee, response.body?.data);
  }

  public async trackUserEvent({ type }: { type: enums.EventType }): Promise<void> {
    await this.api.post("/user/event").send({ type });
  }

  public async sendChat({
    message,
    applicationId,
  }: { message: string; applicationId: string }): Promise<void> {
    await this.api.post(`/application/${applicationId}/chat`).send({ message });
  }

  public async getChat({ applicationId }: { applicationId: string }): Promise<entities.Chat[]> {
    const response = await this.api.get(`/application/${applicationId}/chat`);
    return plainToInstance(entities.Chat, response.body?.data);
  }

  public async updateApplicationEmployee({
    applicationId,
    role,
    employeeId,
  }: { applicationId: string; role: enums.InternalEmployeeType; employeeId: string }): Promise<void> {
    await this.api.patch(`/application/${applicationId}/employee/${role}`).send({ employeeId });
  }

  public async rescindDeal(dealId: string, withdrawnReason: string): Promise<void> {
    await this.api
      .patch("/application/status")
      .send([
        { withdrawnReason, applicationId: dealId, status: enums.ApplicationStatus.HOMEOWNER_RESCINDED },
      ]);
  }

  public async rejectDeal(dealId: string, declineReason: string): Promise<void> {
    await this.api.patch("/application/status").send([
      {
        declineReason,
        applicationId: dealId,
        status: enums.ApplicationStatus.PENDING_DECLINE,
        force: true,
      },
    ]);
  }

  public async approveDeal(dealId: string, lenderNotes: string): Promise<void> {
    await this.api.patch("/application/status").send([
      {
        lenderNotes,
        applicationId: dealId,
        status: enums.ApplicationStatus.APPROVED_FOR_COMMITMENT_LETTER,
        force: true,
      },
    ]);
  }

  public async reopenDeal(dealId: string): Promise<entities.ApplicationT> {
    const response = await this.api.post(`/application/${dealId}/reopen`);
    return plainToInstance(entities.Application, response.body as object);
  }

  public async updateInsurancePolicy(
    applicationId: string,
    insurancePolicyId: string,
    uploadedFileId: string | undefined,
    insurancePolicy: Partial<entities.InsurancePolicy>
  ): Promise<entities.InsurancePolicy> {
    const response = await this.api
      .patch(`/application/${applicationId}/insurance-policy/${insurancePolicyId}`)
      .send({ ...insurancePolicy, uploadedFileId });
    return plainToInstance(entities.InsurancePolicy, response.body as object);
  }

  public async createInsurancePolicy(
    applicationId: string,
    uploadedFileId: string,
    insurancePolicy?: Partial<entities.InsurancePolicy>
  ): Promise<entities.InsurancePolicy> {
    const response = await this.api
      .post(`/application/${applicationId}/insurance-policy`)
      .send({ ...insurancePolicy, uploadedFileId });
    return plainToInstance(entities.InsurancePolicy, response.body as object);
  }

  public async getInsurancePolicy(
    applicationId: string,
    insurancePolicyId: string
  ): Promise<entities.InsurancePolicy> {
    const response = await this.api.get(
      `/application/${applicationId}/insurance-policy/${insurancePolicyId}`
    );
    return plainToInstance(entities.InsurancePolicy, response.body as object);
  }

  /**
   * Delete an insurance policy file based on the uploaded file id.
   * If that is the last file in the insurance policy, the insurance policy will be deleted.
   */
  public async deleteInsurancePolicyFile(applicationId: string, uploadedFileId: string): Promise<void> {
    await this.api.delete(`/application/${applicationId}/insurance-policy/${uploadedFileId}`);
  }

  public async getDocumentsNeedingApproval(
    filterTypes?: ("broker" | "applicant" | "conveyancer")[]
  ): Promise<entities.UploadedFile[]> {
    const results = await this.api
      .get("/document/file/approval")
      .query(filterTypes ? { types: filterTypes.join(",") } : {});
    return plainToInstance(entities.UploadedFile, results.body as object[]);
  }

  public async setDocumentType({ id, typeId }: { id: string; typeId: string }): Promise<void> {
    await this.api.patch(`/document/file/${id}`).send({ typeId });
  }

  public async addBrokerToApp({
    brokerId,
    applicationId,
  }: { brokerId: string; applicationId: string }): Promise<void> {
    await this.api.post(`/application/${applicationId}/broker/${brokerId}/connect`);
  }

  public async deleteInvite({ inviteId }: { inviteId: string }): Promise<void> {
    await this.api.delete(`/user/invite/${inviteId}`);
  }

  public async brokerToBrokerAppInvite({
    applicationId,
    emails,
  }: { emails: string[]; applicationId: string }): Promise<entities.Invite[]> {
    const response = await this.api.post(`/application/${applicationId}/broker/invite`).send({ emails });
    return plainToInstance(entities.Invite, response.body as object[]);
  }

  public async brokerToBrokerTeamInvite({ emails }: { emails: string[] }): Promise<entities.Invite[]> {
    const response = await this.api
      .post("/user/invite")
      .send({ emails, type: enums.InviteType.BROKER_TEAM_CONNECTION });
    return plainToInstance(entities.Invite, response.body as object[]);
  }

  public async setBrokerAsPrimaryForApp({
    brokerId,
    applicationId,
  }: { brokerId: string; applicationId: string }): Promise<void> {
    await this.api.patch(`/application/${applicationId}/broker/${brokerId}/primary`);
  }

  public generateDocumentUrl({
    type,
    applicationId,
  }: {
    type: parsers.docGen.DocGenDocumentType;
    applicationId: string;
  }) {
    return {
      url: `${this._baseURL}/document/generate/${type}/preview`,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: {
        applicationId,
      },
    };
  }
}

export interface InitiateIDVerificationArgs {
  applicantId: string;
}

export interface AnswerIDVerificationArgs {
  applicantId: string;
  answers: types.KiqAnswer[];
  attemptId: string;
}

export interface GroupingLtvOverTimeData {
  administrativeArea: parsers.portfolio.LoanToValueOverTimeData[];
  fsa: parsers.portfolio.LoanToValueOverTimeData[];
  creditScore: parsers.portfolio.LoanToValueOverTimeData[];
  yearCohort: parsers.portfolio.LoanToValueOverTimeData[];
  ungrouped: parsers.portfolio.LoanToValueOverTimeData[];
}

export interface GroupingPropertyValueOverTimeData {
  administrativeArea: parsers.portfolio.PropertyValueOverTimeData[];
  fsa: parsers.portfolio.PropertyValueOverTimeData[];
  creditScore: parsers.portfolio.PropertyValueOverTimeData[];
  yearCohort: parsers.portfolio.PropertyValueOverTimeData[];
  ungrouped: parsers.portfolio.PropertyValueOverTimeData[];
}

export interface PortfolioFilters {
  status?: enums.LoanStatus;
  portfolios?: enums.Portfolio[];
  startDate?: Date;
  endDate?: Date;
  startLoanActivityDate?: Date;
  endLoanActivityDate?: Date;
  country?: enums.Country;
  productType?: enums.ProductType;
  termLength?: number;
  occupancy?: "primary" | "nonprimary";
}

const constructPortfolioFilter = ({
  status,
  portfolios,
  startDate,
  endDate,
  startLoanActivityDate,
  endLoanActivityDate,
  country,
  productType,
  termLength,
  occupancy,
}: PortfolioFilters) => ({
  portfolios,
  startDate: startDate?.toISOString(),
  endDate: endDate?.toISOString(),
  startLoanActivityDate: startLoanActivityDate?.toISOString(),
  endLoanActivityDate: endLoanActivityDate?.toISOString(),
  country,
  productType,
  termLength,
  status,
  occupancy,
});

const fractionSingleton = new FractionClient();

export default fractionSingleton;
