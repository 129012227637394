import { Suspense } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "src/components/Route/Loading";
import { ExistingDocumentList } from "src/embeds/Classifier/components/ExistingDocumentList";

export function AppDocumentList({ mutatable }: { mutatable?: boolean }) {
  const { id } = useParams();
  return (
    <Suspense fallback={<Loading />}>
      <ExistingDocumentList mutatable={mutatable} enabled applicationId={id} />
    </Suspense>
  );
}
