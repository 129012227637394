import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { cn } from "src/utilities/shadcnUtils";

export function OccupancySelect({
  onValueChange,
  className,
  value,
}: {
  value?: "primary" | "nonprimary";
  onValueChange?: (value: "primary" | "nonprimary") => void;
  className?: string;
}) {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className={cn("col-span-2", className)}>
        <SelectValue placeholder="Select an occupancy type" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Occupancy</SelectLabel>
          <SelectItem value="primary">Primary</SelectItem>
          <SelectItem value="nonprimary">Non-Primary</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
