import { Children, ReactNode, cloneElement } from "react";
import { useParams } from "react-router-dom";
import { DocumentChecklist } from "src/components/DocumentChecklist";
import { InsurancePoliciesBlock } from "src/components/InsurancePolicies";
import { TaskChecklist } from "src/components/TaskChecklist";

export const AppDetailsOverview = ({ children }: { children?: ReactNode }) => {
  const { id } = useParams();

  return (
    <div className="gap-y-2 flex flex-col">
      {Children.map(children, (child) =>
        // @ts-ignore
        child && typeof child === "object" ? cloneElement(child, { applicationId: id }) : null
      )}
      <InsurancePoliciesBlock applicationId={id} />
      <DocumentChecklist applicationId={id} />
      <TaskChecklist applicationId={id} className="max-w-full overflow-y-scroll" />
    </div>
  );
};
