import { debounce } from "@fraction/shared";
import { DocumentProps, usePDF } from "@react-pdf/renderer";
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import Skeleton from "src/components/Skeleton";
import { Download } from "src/icons";
import { useMutation } from "src/lib";
import { downloadFile } from "src/utilities/file/download";
import { cn } from "src/utilities/shadcnUtils";
import ScrollablePDFViewer from "./ScrollablePDFViewer";

/**
 * A PDF viewer that can be used to display PDFs in the browser, including on mobile. Memoize the children
 * to prevent unnecessary re-renders.
 */
export const PDFViewer = forwardRef(
  (
    {
      children,
      filename,
      className,
      download,
      onDownload,
      url,
      toolbar = "header",
      toolbarElements,
      pdfClassName,
      renderTextLayer,
    }: {
      toolbar?: "header" | "footer";
      children?: ReactElement<DocumentProps, string | JSXElementConstructor<any>>;
      filename?: string;
      className?: string;
      pdfClassName?: string;
      download?: boolean | string;
      toolbarElements?: ReactNode;
      onDownload?: () => void;
      url?: string;
      renderTextLayer?: boolean;
    },
    ref
  ) => {
    const [instance, updateInstance] = usePDF({ document: children });

    const debouncedUpdateInstance = debounce(updateInstance, 100);

    useEffect(() => {
      if (children) {
        debouncedUpdateInstance(children);
      }
    }, [children]);

    const downloadMutation = useMutation({
      mutationFn: async (event: any) => {
        const downloadUrl = url || instance.url;
        if (!downloadUrl) {
          return null;
        }
        onDownload?.();
        return downloadFile(downloadUrl, `${filename}.pdf`);
      },
    });

    useImperativeHandle(ref, () => ({
      download: downloadMutation.mutateAsync,
    }));

    if (instance.loading) {
      return <Skeleton width="100%" height="100%" />;
    }

    if (instance.error) {
      return <div>Something went wrong: {instance.error}</div>;
    }

    const Toolbar = () =>
      download ? (
        <div
          className={cn(
            "h-12 bg-gray w-full items-center flex px-6 justify-between",
            toolbar === "header" ? "rounded-t align-top" : "rounded-b align-bottom"
          )}
        >
          {toolbarElements ? toolbarElements : <div />}

          <button
            onClick={downloadMutation.mutate}
            className="flex flex-row items-center gap-3 text-white text-sm"
          >
            {typeof download === "string" ? download : null}
            <Download className="text-white" />
          </button>
        </div>
      ) : null;

    return (
      <div className={className}>
        {toolbar === "header" && <Toolbar />}
        <ScrollablePDFViewer
          className={cn("h-full w-full", pdfClassName)}
          newTab={false}
          file={url || instance.url}
          renderTextLayer={renderTextLayer}
        />
        {toolbar === "footer" && <Toolbar />}
      </div>
    );
  }
);
