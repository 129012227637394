/* eslint-disable react/button-has-type */
import { SerializedStyles } from "@emotion/react";
import { ReactNode, useMemo } from "react";
import { createStyles } from "src/styles";
import { tokens } from "src/styles/figma";
import { cn } from "src/utilities/shadcnUtils";

import ThreeDotsWave from "../ThreeDotsWave";

export interface ButtonProps {
  onClick?: () => void;
  children?: ReactNode;
  disabled?: boolean;
  size?: "small" | "medium" | "standard" | "large";
  variant?: "standard" | "outlined" | "inverted";
  color?: string;
  type?: "button" | "submit" | "reset";
  style?: SerializedStyles | (SerializedStyles | undefined)[];
  textStyle?: SerializedStyles;
  styleOverrides?: SerializedStyles | (SerializedStyles | undefined)[]; // style but with higher priority
  loading?: boolean;
  fullWidth?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
}

const styles = createStyles(({ colors, borderRadius, spacing }) => ({
  base: {
    border: "none",
    boxShadow: "none",
    "&:hover:enabled": {
      cursor: "pointer",
    },
    "&:hover:disabled": {
      cursor: "not-allowed",
    },
    "&:focus-visible": {
      outline: `4px solid ${colors.amber500}`,
    },

    // text
    fontSize: 16,
    fontWeight: 550,
    fontFamily: "Avenir",

    // base colors (generally overwritten by types)
    background: colors.darkgrey500,
    borderColor: colors.darkgrey500,
    color: colors.darkgrey500,
    "&:active:enabled": {
      background: colors.darkgrey600,
      borderColor: colors.darkgrey600,
      color: colors.darkgrey600,
    },
  },
  fullWidth: {
    width: "100%",
  },
  iconButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  startIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: spacing[2],
  },
  endIcon: {
    display: "flex",
    alignItems: "center",
    marginLeft: spacing[2],
  },

  // sizes
  smallSize: {
    padding: `${spacing[1]}px ${spacing[3]}px`,
    fontSize: 12,
  },
  mediumSize: {
    padding: `${spacing[1.5]}px ${spacing[3.5]}px`,
  },
  standardSize: {
    padding: `${spacing[2]}px ${spacing[4]}px`,
  },
  largeSize: {
    padding: `${spacing[2.5]}px ${spacing[4]}px`,
  },

  // variants
  standardVariant: {
    color: colors.white,
    "&:active:enabled": {
      color: colors.white,
    },
  },
  outlinedVariant: {
    background: "transparent",
    border: "1px solid",
    "&:active:enabled": {
      background: "transparent",
    },
  },
  invertedVariant: {
    background: "transparent",
    "&:active:enabled": {
      background: "transparent",
    },
  },

  // disabled variants
  standardDisabledButton: {
    background: colors.platinum,
    color: colors.silver,
    borderColor: colors.platinum,
  },
  outlinedDisabledButton: {
    color: colors.silver,
    background: "transparent",
    border: "none",
  },
  invertedDisabledButton: {
    color: colors.silver,
    background: "transparent",
    border: "none",
  },

  // implementation specific styling
  primaryButton: {
    background: colors.green600,
    color: colors.green600,
    borderColor: colors.green600,
    "&:active:enabled": {
      background: colors.green700,
      color: colors.green700,
      borderColor: colors.green700,
    },
    "&:hover:enabled": {
      background: colors.green700,
      borderColor: colors.green700,
    },
  },
  warningButton: {
    background: colors.amber800,
    color: colors.amber800,
    borderColor: colors.amber800,
    "&:active:enabled": {
      background: colors.amber900,
      color: colors.amber900,
      borderColor: colors.amber900,
    },
    "&:hover:enabled": {
      background: colors.amber900,
      borderColor: colors.amber900,
    },
  },
  destructiveButton: {
    background: colors.red,
    color: colors.red,
    borderColor: colors.red,
    "&:active:enabled": {
      background: colors.red800,
      color: colors.red800,
      borderColor: colors.red800,
    },
    "&:hover:enabled": {
      background: colors.red800,
      borderColor: colors.red800,
    },
  },
  monoButton: {
    background: colors.black,
    color: colors.black,
    borderColor: colors.black,
    "&:active:enabled": {
      background: colors.darkgrey,
      color: colors.darkgrey,
      borderColor: colors.darkgrey,
    },
    "&:hover:enabled": {
      background: colors.darkgrey,
      borderColor: colors.darkgrey,
    },
  },

  // loader styles
  hidden: { overflow: "hidden", height: 0 },
  loadingContainer: {
    minHeight: 24, // height the same as default text height
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SIZE_STYLE_MAP = {
  small: styles.smallSize,
  medium: styles.mediumSize,
  standard: styles.standardSize,
  large: styles.largeSize,
};

const VARIANT_STYLE_MAP = {
  standard: styles.standardVariant,
  outlined: styles.outlinedVariant,
  inverted: styles.invertedVariant,
};

const DISABLED_VARIANT_STYLE_MAP = {
  standard: styles.standardDisabledButton,
  outlined: styles.outlinedDisabledButton,
  inverted: styles.invertedDisabledButton,
};

/*
 * Future TODOS:
 * - Add hover styling (when available from design)
 */

/**
 * A barebones button component, with some base styling and functionality, the rest is up to you!
 * Should generally not be used directly, but rather as a primitive for constructing more complex buttons.
 */
export const BaseButton = ({
  onClick,
  disabled,
  children,
  size = "standard",
  variant = "standard",
  style,
  styleOverrides,
  type = "button",
  fullWidth = false,
  loading,
  className,
}: ButtonProps) => {
  return (
    <>
      <button
        className={cn("w-fit rounded-md", fullWidth && "w-full", className)}
        type={type}
        disabled={disabled || loading}
        onClick={onClick}
        css={[
          styles.base,
          style,
          SIZE_STYLE_MAP[size],
          VARIANT_STYLE_MAP[variant],
          disabled && DISABLED_VARIANT_STYLE_MAP[variant],
          styleOverrides,
        ]}
      >
        {children}
      </button>
    </>
  );
};

// TODO keeping various versions of the button below in the same file for now, but if this file starts to get heavy, consider splitting into seperate files.

export const ButtonWithLoader = ({
  children,
  loading,
  variant = "standard",
  color,
  ...rest
}: ButtonProps) => {
  const memoedStyles = useMemo(
    () =>
      createStyles({
        dots: {
          background: variant === "standard" ? tokens.colors.white : color,
        },
      }),
    [color, variant]
  );
  return (
    <BaseButton {...rest} color={color} variant={variant} loading={loading}>
      {loading && (
        <div css={[styles.loadingContainer, rest.size === "small" && { minHeight: 18 }]}>
          <ThreeDotsWave size={rest.size === "small" ? 4 : undefined} dotCss={memoedStyles.dots} />
        </div>
      )}

      {/* we still render the child here so that the button doesn't resize when the loader appears, but we make it invisible */}
      <div css={loading && styles.hidden}>{children}</div>
    </BaseButton>
  );
};

/**
 * Include an icon (or any element) on either side of the button text.
 */
export const IconButton = ({ children, startIcon, endIcon, ...rest }: ButtonProps) => {
  return (
    <ButtonWithLoader {...rest}>
      {startIcon || endIcon ? (
        <div css={styles.iconButtonContainer}>
          {startIcon ? <div css={styles.startIcon}>{startIcon}</div> : null}
          {children}
          {endIcon ? <div css={styles.endIcon}>{endIcon}</div> : null}
        </div>
      ) : (
        children
      )}
    </ButtonWithLoader>
  );
};

export const PrimaryButton = ({ style, ...rest }: ButtonProps) => {
  return (
    <IconButton
      {...rest}
      style={styles.primaryButton}
      styleOverrides={style}
      color={tokens.colors.green600}
    />
  );
};

export const DestructiveButton = ({ style, ...rest }: ButtonProps) => {
  return (
    <IconButton {...rest} style={styles.destructiveButton} styleOverrides={style} color={tokens.colors.red} />
  );
};

export const WarningButton = ({ style, ...rest }: ButtonProps) => {
  return (
    <IconButton {...rest} style={styles.warningButton} styleOverrides={style} color={tokens.colors.amber} />
  );
};

export const MonoButton = ({ style, ...rest }: ButtonProps) => {
  return (
    <IconButton {...rest} style={styles.monoButton} styleOverrides={style} color={tokens.colors.black} />
  );
};
