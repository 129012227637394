import { ChevronDown, ChevronUp, HelpCircleIcon } from "lucide-react";
import NanoClamp from "nanoclamp";
import { ReactNode, useCallback, useState } from "react";
import Skeleton from "src/components/Skeleton";
import Tooltip from "src/components/Tooltip";
import { useClipboard } from "src/hooks/useClipboard";
import { cn } from "src/utilities/shadcnUtils";

export const KeyValue = ({
  label,
  sublabel,
  value,
  loading,
  className,
  clipboard = true,
  tooltip,
  children,
}: {
  sublabel?: string;
  label: string | ReactNode;
  value?: string | ReactNode;
  loading?: boolean;
  className?: string;
  clipboard?: boolean;
  tooltip?: ReactNode;
  // if there are children, this is a collapsible component
  children?: ReactNode;
}) => {
  const onClick = useClipboard();
  const [expanded, setExpanded] = useState(false);
  const onClickCollapseToggle = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);
  return (
    <div>
      <div
        onClick={onClickCollapseToggle}
        className={cn(
          "flex flex-row items-center justify-between p-3 rounded overflow-x-scroll",
          children && "cursor-pointer",
          className
        )}
      >
        <div>
          <div className="flex flex-row">
            <p className="text-sm font-bold">{label}:</p>
            {tooltip ? (
              <Tooltip white text={tooltip}>
                <HelpCircleIcon className="ml-1" size={12} />
              </Tooltip>
            ) : null}
            {children ? (
              expanded ? (
                <ChevronUp className="h-4 w-4 self-center ml-1" />
              ) : (
                <ChevronDown className="h-4 w-4 self-center ml-1" />
              )
            ) : null}
          </div>
          {sublabel ? <p className="text-xs text-gray">{sublabel}</p> : null}
        </div>

        {loading ? (
          <Skeleton width={160} />
        ) : typeof value === "string" ? (
          <NanoClamp
            text={value || "N/A"}
            onClick={onClick}
            lines={2}
            className={cn("text-sm text-right ml-4", clipboard && "hover:font-semibold cursor-pointer")}
          />
        ) : (
          value
        )}
      </div>
      {expanded ? <div className="pl-4 bg-white/75">{children}</div> : null}
    </div>
  );
};
