import { enums, formatters } from "@fraction/shared";
import _ from "lodash";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { ChecklistApp } from "src/api/fraction";
import { ApplicantOverview } from "src/apps/BrokerDashboard/components/ApplicantOverview";
import { ApplicationOverview } from "src/apps/PreFundedDashboard/components/ApplicationOverview";
import { useAuth } from "src/auth";
import { AppTimeline } from "src/components/AppTimeline";
import { ApprovalRejectBlock } from "src/components/ApprovalRejectBlock";
import { BrokerInviteModalButton } from "src/components/BrokerInviteModalButton";
import { BrokerPrimaryBadge } from "src/components/BrokerPrimaryBadge";
import { CancelOrReopenApp } from "src/components/CancelOrReopenApp";
import { ClipboardText } from "src/components/ClipboardText";
import EmployeeCard from "src/components/EmployeeCard";
import { InnerFundingProgress } from "src/components/FundingProgress";
import HorizontallyCollapsibleDrawer from "src/components/HorizontallyCollapsibleDrawer";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useApplicationBrokers } from "src/hooks/useApplicationBrokers";

import { nestedNavLinkStyle } from "./ui/navigation-menu";

type Tab = "overview" | "doc-gen" | "doc-upload" | "comms" | "doc-list";

const Header = ({ tabs }: { tabs?: Tab[] }) => {
  const { id } = useParams();
  if (!tabs?.length || tabs.length < 2) {
    return null;
  }

  return (
    <div className="flex items-center border-b-[1px] border-solid">
      <div className="pt-1 overflow-x-scroll gap-x-0.5 flex flex-row">
        {tabs.includes("overview") ? (
          <NavLink className={nestedNavLinkStyle} to={`/app/${id}/`}>
            Overview
          </NavLink>
        ) : null}
        {tabs.includes("doc-gen") ? (
          <NavLink className={nestedNavLinkStyle} to={`/app/${id}/doc-gen`}>
            Doc Creation
          </NavLink>
        ) : null}
        {tabs.includes("doc-upload") ? (
          <NavLink className={nestedNavLinkStyle} to={`/app/${id}/doc-upload`}>
            Doc Upload
          </NavLink>
        ) : null}
        {tabs.includes("doc-list") ? (
          <NavLink className={nestedNavLinkStyle} to={`/app/${id}/doc-list`}>
            Uploaded docs
          </NavLink>
        ) : null}
        {tabs.includes("comms") ? (
          <NavLink className={nestedNavLinkStyle} to={`/app/${id}/comms`}>
            Communications
          </NavLink>
        ) : null}
      </div>
    </div>
  );
};

export function AppOverviewShell({
  app,
  loading,
  tabs,
  showBdm = true,
  showApproveReject,
}: { app?: ChecklistApp; loading?: boolean; tabs?: Tab[]; showBdm?: boolean; showApproveReject?: boolean }) {
  const { user } = useAuth();
  const title = app?.id ? formatters.application.applicantNames(app) : "App details";
  useDocumentTitle(title);

  const { setPrimaryBroker, pendingBrokerId } = useApplicationBrokers(app?.id);

  const bdm =
    app?.internalEmployees?.find((contact) => contact.type === enums.InternalEmployeeType.BDM) ||
    app?.internalEmployees?.find((contact) => contact.types?.includes(enums.InternalEmployeeType.BDM)) ||
    app?.internalEmployees?.find((contact) => contact.type === enums.InternalEmployeeType.INSIDE_SALES);

  const mortgageSpecialist =
    app?.internalEmployees?.find(
      (contact) => contact.type === enums.InternalEmployeeType.MORTGAGE_SPECIALIST
    ) ||
    app?.internalEmployees?.find((contact) =>
      contact.types?.includes(enums.InternalEmployeeType.MORTGAGE_SPECIALIST)
    );

  return (
    <div className="flex flex-col md:flex-row">
      <HorizontallyCollapsibleDrawer
        direction="left"
        expandedClassName="md:min-w-[300px] md:max-w-[400px] flex-1"
        collapsedClassName="flex-[0.05] max-w-4"
        className="p-4 flex flex-col gap-y-2 md:overflow-y-scroll md:h-[92svh] overscroll-contain"
      >
        <div className="w-full">
          <div className="w-full flex flex-row justify-between">
            <p className="text-md font-medium">{title}</p>
            <CancelOrReopenApp appId={app?.id} loading={loading} status={app?.status} />
          </div>
          <InnerFundingProgress
            rejectReason={app?.declineReason || undefined}
            loading={loading}
            status={app?.status}
            className="mt-2 w-full"
            blockClassName="h-2 w-full"
          />
          {showApproveReject ? <ApprovalRejectBlock app={app} className="mt-2" /> : null}
        </div>
        <ApplicationOverview
          showHeader={false}
          className="border-0 p-0 overflow-y-clip overflow-x-clip"
          loading={loading}
          app={app || undefined}
        />
        <ApplicantOverview
          detailsClassName="border-0 p-0 w-full"
          className="border-0 p-0 overflow-y-clip"
          app={app || undefined}
          loading={loading}
        />
      </HorizontallyCollapsibleDrawer>
      <div className="p-4 pt-2 flex flex-col gap-y-2 border-x-[1px] flex-[2] md:overflow-y-scroll overscroll-contain md:h-[92svh]">
        <Header tabs={tabs} />
        <Outlet />
      </div>
      <HorizontallyCollapsibleDrawer
        direction="right"
        expandedClassName="md:min-w-[300px] md:max-w-[400px] flex-1"
        collapsedClassName="flex-[0.05] max-w-8"
        className="p-4 flex flex-col gap-y-1 overflow-y-scroll overscroll-contain md:h-[92svh]"
      >
        {app?.brokers?.length ? (
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center justify-between">
              <p className="text-lg font-bold">Brokers on deal</p>
              <BrokerInviteModalButton
                applicationId={app?.id}
                type={enums.InviteType.BROKER_TEAM_CONNECTION}
              />
            </div>
            {_.sortBy(app?.brokers || [], (x) => (x.primary ? 1 : 0))
              ?.reverse()
              ?.map((broker) => (
                <div key={broker.id} className="border rounded p-2">
                  <div className="flex flex-row items-center justify-between w-full overflow-x-clip">
                    <b className="font-serif-deck flex-1 truncate">
                      {formatters.user.userName(broker.user) || broker.user?.email}{" "}
                      {broker.user?.id === user?.id ? "(You)" : ""}
                    </b>
                    <BrokerPrimaryBadge
                      onClickSetPrimary={setPrimaryBroker.mutateAsync}
                      pending={setPrimaryBroker.isPending && pendingBrokerId === broker.id}
                      broker={broker}
                    />
                  </div>
                  <div className="flex flex-col">
                    {broker?.user?.email ? (
                      <ClipboardText className="text-sm">{broker?.user?.email}</ClipboardText>
                    ) : null}
                    {broker?.user?.phone ? (
                      <ClipboardText className="text-sm">
                        {formatters.phone.formatPhoneNumber(broker?.user?.phone, {
                          stripInternationalCode: true,
                        })}
                      </ClipboardText>
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
        ) : null}
        {mortgageSpecialist && bdm ? (
          <>
            <p className="text-lg font-bold">Fraction contacts</p>
            {showBdm ? (
              <EmployeeCard
                type="BDM"
                className="w-full border border-gray-200 p-4 rounded-md"
                contact={bdm}
              />
            ) : null}
            <EmployeeCard
              type="Mortgage specialist"
              className="w-full border border-gray-200 p-4 rounded-md"
              contact={mortgageSpecialist}
            />
            <AppTimeline applicationId={app?.id} />
          </>
        ) : null}
      </HorizontallyCollapsibleDrawer>
    </div>
  );
}
