import { enums } from "@fraction/shared";
import _ from "lodash";
import { useMemo, useState } from "react";
import { ConveyancerSelect } from "src/apps/LOS/components/ConveyancerSelect";
import { ProvinceSelect } from "src/apps/LOS/components/ProvinceSelect";
import { useChecklistModal } from "src/components/AppChecklistModal";
import { AppsPipelineView } from "src/components/AppsPipelineView";
import { Input } from "src/components/ui/input";
import { useInfiniteApplicationsQuery } from "src/hooks/useApplication";
import { useApplicationsSearch } from "src/hooks/useApplicationsSearch";
import { useInputState } from "src/hooks/useInputState";
import { useDebounce } from "use-debounce";

export function FundedPipeline() {
  const [q, setQ] = useInputState("");
  const [debouncedQ] = useDebounce(q, 100);
  const {
    data: fetchedApps,
    count,
    isFetching,
    dataUpdatedAt,
    refetch,
  } = useInfiniteApplicationsQuery({
    enabled: !debouncedQ?.trim()?.length,
    status: "realization",
    accountType: "employee",
  });
  const { data: searchApps } = useApplicationsSearch({ enabled: !!debouncedQ?.trim(), query: debouncedQ });
  const apps = debouncedQ?.trim()?.length ? searchApps : fetchedApps;

  const [provinceFilter, setProvinceFilter] = useState<string | null>(null);
  const [conveyancerFilter, setConveyancerFilter] = useState<enums.ConveyancerGroup | null>(null);
  const filteredApps = useMemo(
    () =>
      apps?.filter((app) => {
        if (!provinceFilter && !conveyancerFilter) {
          return app;
        }
        if (provinceFilter && conveyancerFilter) {
          return (
            app.property?.administrativeArea === provinceFilter &&
            app.conveyancerGroupId === conveyancerFilter
          );
        }
        if (provinceFilter) {
          return app.property?.administrativeArea === provinceFilter;
        }
        if (conveyancerFilter) {
          return app.conveyancerGroupId === conveyancerFilter;
        }
      }),
    [provinceFilter, conveyancerFilter, apps]
  );
  const sortedApps = useMemo(() => _.sortBy(filteredApps, "closeDate"), [filteredApps]);
  const { setShowModal, ChecklistModal } = useChecklistModal();

  return (
    <AppsPipelineView
      showSalesPerson={false}
      showLenderName
      apps={sortedApps}
      isFetching={isFetching}
      handleRefetchClick={refetch}
      totalCount={count}
      dataUpdatedAt={dataUpdatedAt}
      setShowModal={setShowModal}
      visibleStages={[
        enums.LoanStatus.ACTIVE,
        enums.LoanStatus.PAYOUT_REQUESTED,
        enums.LoanStatus.PAYOUT_STATEMENT_SENT,
        enums.LoanStatus.PAYOUT_RECEIVED,
        enums.LoanStatus.PAID_OUT,
      ]}
      groupBy="loan.status"
      modal={<ChecklistModal showRetoolButton />}
      header={
        <div className="flex flex-row gap-x-8">
          <ProvinceSelect
            className="w-[300px]"
            onValueChange={setProvinceFilter}
            value={provinceFilter || undefined}
          />
          <ConveyancerSelect
            className="w-[300px]"
            value={conveyancerFilter || undefined}
            onValueChange={setConveyancerFilter}
          />
          <Input placeholder="🔎 Search for app" onChange={setQ} value={q} />
        </div>
      }
    />
  );
}
