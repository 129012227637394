import { entities, enums, formatters, types } from "@fraction/shared";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import _ from "lodash";
import { CheckCircle, CircleHelpIcon, Clock, Loader, Trash } from "lucide-react";
import { useCallback } from "react";
import { useDeleteDocuments } from "src/apps/PostFundedDashboard/queries";
import Dropzone, { FileWithEnumType } from "src/components/Dropzone";
import { PDFViewer } from "src/components/PDFViewer";
import Tooltip from "src/components/Tooltip";
import { Badge } from "src/components/ui/badge";
import { useUploadDocuments } from "src/hooks/useSimpleUploadDocuments";
import { useMutation } from "src/lib";
import { cn } from "src/utilities/shadcnUtils";
import useModal from "../hooks/useModal";

const DocumentStatusIcon = ({
  approval,
  className,
}: { approval?: enums.ApplicationTaskApprovalStatus; className?: string }) => {
  return (
    <div
      className={cn(
        "rounded-full w-fit p-0",
        approval === enums.ApplicationTaskApprovalStatus.APPROVED
          ? "bg-green"
          : approval === enums.ApplicationTaskApprovalStatus.REQUESTED
          ? "bg-yellow"
          : approval === enums.ApplicationTaskApprovalStatus.REJECTED
          ? "bg-red"
          : approval === enums.ApplicationTaskApprovalStatus.NEEDS_WORK
          ? "bg-orange"
          : undefined,
        className
      )}
    >
      {approval === enums.ApplicationTaskApprovalStatus.APPROVED ? (
        <CheckCircle height={14} width={14} className="text-white  fill-green" />
      ) : approval === enums.ApplicationTaskApprovalStatus.REQUESTED ? (
        <Clock height={14} width={14} className="text-white fill-yellow" />
      ) : approval === enums.ApplicationTaskApprovalStatus.REJECTED ? (
        <CrossCircledIcon height={14} width={14} className="text-white" />
      ) : approval === enums.ApplicationTaskApprovalStatus.NEEDS_WORK ? (
        <CircleHelpIcon height={14} width={14} className="text-white" />
      ) : null}
    </div>
  );
};

export function DocumentChecklistRow({
  item,
  allDebts,
  applicationId,
  onUploadSuccess,
  canUpload,
  disabled,
}: {
  item: types.DocumentChecklistItem;
  allDebts: entities.Debt[];
  applicationId?: string;
  onUploadSuccess?: () => Promise<any>;
  canUpload?: boolean;
  disabled?: boolean;
}) {
  const debt = item?.debtId ? allDebts?.find((debt) => debt.id === item?.debtId) : null;
  const refetchFromDeleteMutation = useMutation({
    mutationFn: onUploadSuccess,
  });
  const refetchFromUploadMutation = useMutation({
    mutationFn: onUploadSuccess,
  });
  const { mutateAsync: uploadDocuments, isPending: uploadPending_ } = useUploadDocuments({
    onUploadSuccess: () => refetchFromUploadMutation.mutateAsync(),
  });
  const {
    mutateAsync: deleteDocument,
    isPending: deletePending_,
    documentDeleting,
  } = useDeleteDocuments({
    onSuccess: refetchFromDeleteMutation.mutateAsync,
  });

  const deletePending = deletePending_ || refetchFromDeleteMutation.isPending;
  const uploadPending = uploadPending_ || refetchFromUploadMutation.isPending;

  const { showModal, closeModal } = useModal();

  const onClickDelete = useCallback(
    async (documentId?: string) => {
      if (!documentId && !item.id) {
        throw new Error("No document id");
      }
      deleteDocument(documentId || item.id!);
      closeModal?.();
    },
    [item?.id, closeModal]
  );

  const handleClickPreview = useCallback(
    (documentUrl?: string, documentId?: string) => {
      const handleClickDelete = () => {
        showModal({
          message: "Are you sure you want to delete the file?",
          actions: [
            { text: "Cancel", type: "inverse", action: closeModal },
            {
              text: "Delete",
              type: "urgent",
              action: () => {
                closeModal();
                onClickDelete(documentId);
              },
            },
          ],
        });
      };

      if (!documentUrl && !item.documentUrl) {
        return;
      }
      showModal({
        shovable: true,
        children: (
          <PDFViewer
            renderTextLayer
            filename={item.documentName}
            toolbar="footer"
            download
            pdfClassName="h-[700px]"
            url={documentUrl || item.documentUrl}
            toolbarElements={
              <button disabled={deletePending} onClick={handleClickDelete}>
                {!deletePending ? <Trash className="text-white" height={14} /> : "Deleting..."}
              </button>
            }
          />
        ),
      });
    },
    [item.documentUrl]
  );

  const handleDropDocument = useCallback(
    (docs: FileWithEnumType[]) => {
      if (!item?.document || !applicationId) {
        throw new Error("No document type found");
      }
      uploadDocuments({
        assets: docs,
        applicationId,
        documentTypeName: item?.document,
        applicantId: item?.applicantId,
        debtId: item?.debtId,
        incomeId: item?.incomeId,
        date: item.year ? new Date(item.year) : undefined,
      });
    },
    [item?.type, applicationId, uploadDocuments, item?.applicantId, item?.debtId, item?.incomeId]
  );

  return (
    <Tooltip
      white
      key={item.id}
      text={
        <div>
          <p>
            <b>{item?.documentName}</b>
          </p>
          <p className="text-xs mt-1">{item?.documentDescription}</p>
        </div>
      }
    >
      <div
        key={item.id}
        className={cn(
          "flex flex-col md:flex-row flex-wrap md:items-center justify-between p-3 rounded gap-1 odd:bg-gray-100 bg-white hover:bg-gray-200 relative",
          disabled && "opacity-40"
        )}
      >
        <div className="md:max-w-[50%]">
          <p>
            {item.documentName || _.startCase(item.document)} {item.year ? `for ${item.year}` : ""}
          </p>

          {item.applicantName && !item.incomeType ? (
            <p className="text-sm font-medium text-gray-600">For {item.applicantName}</p>
          ) : null}
          {item.applicantName && item.incomeType ? (
            <p className="text-sm font-medium text-gray-600">{formatIncomeItem(item)}</p>
          ) : null}
          {item.debtWithWho ? (
            <p className="text-sm font-medium text-gray-600">
              For {item.debtWithWho || item.debtDescription || ""}{" "}
              {item.debtType
                ? formatters.enums.DEBT_TYPE_MAP[item.debtType as enums.DebtType]?.toLowerCase()
                : ""}
              {debt?.currentlyOwing
                ? ` (${formatters.number.getCurrencyFromNumber(debt.currentlyOwing)})`
                : ""}
            </p>
          ) : null}
          {item?.notes ? (
            <p className="mt-1 bg-gray-400 p-1.5 px-2 text-sm rounded">
              <b>Notes:</b> {item.notes}
            </p>
          ) : null}
        </div>
        <div className="flex flex-row gap-x-1">
          {!deletePending ? (
            <>
              <Dropzone
                accept={[types.MimeType.PDF, types.MimeType.PNG, types.MimeType.JPEG, types.MimeType.HEIC]}
                disabled={!canUpload || uploadPending}
                multi
                onDrop={handleDropDocument}
                dragActiveChildren={
                  <Badge className="w-full" variant="default" loading={uploadPending}>
                    Drag file here
                  </Badge>
                }
              >
                <Badge className="w-full" variant="default">
                  {uploadPending ? (
                    <>
                      <Loader height={14} className="text-white animate-spin" /> Uploading...
                    </>
                  ) : (
                    "Upload doc"
                  )}
                </Badge>
              </Dropzone>
            </>
          ) : null}
        </div>
        <div className="flex flex-row gap-2 mt-1 flex-wrap w-full">
          {item?.documents?.map((doc) => (
            <Tooltip
              white
              key={item.id}
              text={
                <div>
                  <p className="text-xs">
                    <b>
                      {item?.approval
                        ? item.approval === enums.ApplicationTaskApprovalStatus.REQUESTED
                          ? "Pending Fraction review"
                          : `Document ${formatters.enums.APPROVAL_STATUS_MAP[item?.approval]?.toLowerCase()}`
                        : "Waiting for upload"}
                    </b>
                    <i>{item?.notes ? `: ${item?.notes}` : ""}</i>
                  </p>
                </div>
              }
            >
              <button
                onClick={() => handleClickPreview(doc.documentUrl, doc.id)}
                className="border border-gray-400 rounded-full px-3 py-1 hover:border-green-400 hover:bg-green-300 flex flex-row gap-1 items-center"
                key={doc?.id}
              >
                {documentDeleting === doc.id ? (
                  <>
                    <Loader height={13} width={13} className="animate-spin" />
                    <p className="text-xs truncate">
                      <b>Deleting</b> {doc.uploadedFileName}...
                    </p>
                  </>
                ) : (
                  <>
                    <DocumentStatusIcon approval={doc.approval} />
                    <p className="text-xs truncate">{doc.uploadedFileName}</p>
                  </>
                )}
              </button>
            </Tooltip>
          ))}
        </div>
        {disabled ? <div className="absolute top-0 left-0 right-0 bottom-0 cursor-not-allowed" /> : null}
      </div>
    </Tooltip>
  );
}

const formatIncomeItem = (item: types.DocumentChecklistItem) => {
  let str = `For ${formatters.user.possessive(item.applicantName)}`;

  if (item?.incomeAmount) {
    str = `${str} ${formatters.number.getCurrencyWords(item.incomeAmount)}`;
  }

  if (item?.incomeType) {
    str = `${str} ${_.startCase(item.incomeType).toLowerCase()}`;
  }

  const jobTitle = item.incomeJobTitle || item.incomeDescription;
  if (
    item.incomeType &&
    jobTitle &&
    [enums.IncomeType.EMPLOYMENT_INCOME, enums.IncomeType.SELF_EMPLOYMENT_INCOME].includes(
      item.incomeType as enums.IncomeType
    )
  ) {
    return `${str} as a ${jobTitle?.replace("|", "in").toLowerCase()}`;
  }

  return str;
};
