import { enums } from "@fraction/shared";
import { FileCheckIcon, FileXIcon } from "lucide-react";
import { useCallback } from "react";
import { ChecklistApp } from "src/api/fraction";
import { useApplication } from "src/hooks/useApplication";
import useModal from "src/hooks/useModal";
import { cn } from "src/utilities/shadcnUtils";

export const ApprovalRejectBlock = ({ className, app }: { app?: ChecklistApp; className?: string }) => {
  const { rejectDeal, approveDeal } = useApplication({ id: app?.id, fetchEnabled: false });
  const { showModal } = useModal();

  const handleOpenReject = useCallback(() => {
    showModal({
      prompt: "Reason for rejection",
      message: "Are you sure you want to reject this deal?",
      header: "Reject deal",
      actions: [
        {
          text: "Never mind",
          action: () => {},
          type: "inverse",
        },
        {
          type: "urgent",
          text: "Yes, reject deal",
          action: (input) => {
            return input && rejectDeal.mutateAsync({ rejectReason: input });
          },
          promptRequired: true,
        },
      ],
    });
  }, [showModal]);

  const handleOpenApprove = useCallback(() => {
    showModal({
      prompt: "Notes for approval",
      message: "Are you sure you want to approve this deal?",
      header: "Approve deal",
      actions: [
        {
          text: "Never mind",
          action: () => {},
          type: "inverse",
        },
        {
          text: "Yes, approve deal",
          action: (input) => {
            return input && approveDeal.mutateAsync({ lenderNotes: input });
          },
          promptRequired: true,
          type: "primary",
        },
      ],
    });
  }, [showModal]);

  if (
    !app?.status ||
    ![
      enums.ApplicationStatus.MISSING_INFORMATION,
      enums.ApplicationStatus.UNDERWRITING,
      enums.ApplicationStatus.PARTIALLY_COMPLETED,
    ].includes(app.status)
  ) {
    return null;
  }

  return (
    <div className={cn("flex flex-row flex-wrap gap-1", className)}>
      <button
        onClick={handleOpenApprove}
        className="py-1 bg-gray-400 px-4 group border-[1.5px] border-gray-400 hover:border-green-400 rounded-full hover:bg-green-100 flex flex-row items-center text-black"
      >
        <FileCheckIcon className="w-4 h-4 stroke-[1.5px] group-hover:fill-green-200 group-hover:text-green" />
        <p className="text-xs font-medium leading-4 ml-1.5 group-hover:text-green line-clamp-1 text-ellipsis overflow-x-clip text-start">
          Approve app
        </p>
      </button>
      <button
        onClick={handleOpenReject}
        className="py-1 px-4 group border-[1.5px] bg-gray-400 border-gray-400 hover:border-red-400 rounded-full hover:bg-red-100 flex flex-row items-center text-black"
      >
        <FileXIcon className="w-4 h-4 stroke-[1.5px] group-hover:fill-red-200  group-hover:text-red" />
        <p className="text-xs font-medium leading-4 ml-1.5  group-hover:text-red line-clamp-1 text-ellipsis overflow-x-clip text-start">
          Reject app
        </p>
      </button>
    </div>
  );
};
