import _ from "lodash";
import { CircleCheck, CircleHelp, CircleMinus, CircleX, NotebookPen } from "lucide-react";
import randomcolor from "randomcolor";
import { useCallback, useMemo, useState } from "react";
import { TextInput, XButton } from "src/components";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { LabeledDocument } from "src/embeds/Classifier/types";
import { createStyles } from "src/styles";

import { colors, enums } from "@fraction/shared";
import { ModalContent } from "src/hooks/useModal";
import { cn } from "src/utilities/shadcnUtils";

const styles = createStyles({
  item: {
    marginBottom: 8,
  },
  labelName: {
    color: "white",
    fontWeight: 600,
    fontSize: "0.8em",
    padding: "5px 7px",
    borderRadius: "4px 4px 0 0",
    display: "flex",
  },
  labelNameText: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  deleteButton: {
    background: "transparent",
    marginLeft: "auto",
    flexShrink: 0,
    width: 15,
    height: 15,
    padding: 0,
  },
  labelSubdocuments: {
    border: "1px solid",
    borderRadius: "0 0 4px 4px",
    borderColor: colors.palette.GREY_300,
    borderTop: 0,
    listStyle: "none",
    margin: 0,
    padding: "0px 4px 4px 4px",
  },
  subdocumentName: {
    fontSize: "0.8em",
    color: colors.palette.GREY_700,
    margin: "4px 0 6px 0",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  labeledPages: {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
  },
});

const NoteModal = ({ value, onChangeValue }: { value?: string; onChangeValue: (notes: string) => void }) => {
  const [notes, setNotes] = useState(value);
  const handleChange = useCallback(
    (update: string) => {
      onChangeValue?.(update);
      setNotes(update);
    },
    [onChangeValue]
  );

  return (
    <div className="">
      <p className="text-lg font-bold mb-4">Update notes for the document</p>
      <TextInput value={notes} onChange={handleChange} />
    </div>
  );
};

const LabeledDocumentItem = ({
  labeledDocument,
  onDelete,
  onChangeStatus,
  onChangeNotes,
  showModal,
}: {
  labeledDocument: LabeledDocument;
  onDelete: (labeledDocument: LabeledDocument) => void;
  onChangeStatus: (firstPageId: string, status: enums.ApplicationTaskApprovalStatus) => void;
  onChangeNotes: (firstPageId: string, note: string) => void;
  showModal: (modal: ModalContent | null) => void;
}) => {
  if (!labeledDocument) {
    return null;
  }

  const handleDeletion = useCallback(
    () => onDelete(labeledDocument),
    [labeledDocument?.documentId, onDelete]
  );
  const handleChangeStatus = useCallback(
    (status: enums.ApplicationTaskApprovalStatus) => {
      onChangeStatus(labeledDocument.pages?.[0]?.id, status);
    },
    [onChangeStatus, labeledDocument.pages?.[0]?.id]
  );

  const handleChangeNotes = useCallback(
    (notes: string) => {
      onChangeNotes(labeledDocument.pages?.[0]?.id, notes);
    },
    [onChangeNotes, labeledDocument.pages?.[0]?.id]
  );

  const handleClickNote = useCallback(() => {
    showModal({
      message: <NoteModal value={labeledDocument.notes} onChangeValue={handleChangeNotes} />,
      actions: [
        {
          text: "Clear notes",
          type: "urgent",
          action: () => {
            handleChangeNotes("");
          },
        },
        {
          text: "Save",
          type: "primary",
        },
      ],
    });
  }, [labeledDocument.id, labeledDocument.notes, handleChangeNotes]);

  const groupedByDocument = Object.entries(_.groupBy(labeledDocument.pages, "document.id"));
  if (!labeledDocument?.type) {
    return null;
  }
  const labelUser = labeledDocument.pages?.[0]?.labels?.find((l) => l.type === "applicant")?.label;
  const labelDebts = labeledDocument.pages?.[0]?.labels
    ?.filter((l) => l.type === "debt")
    ?.map((l) => l.label);

  const bgStyle = useMemo(
    () => ({
      backgroundColor: randomcolor({ seed: labeledDocument?.type?.fullName, luminosity: "dark" }),
    }),
    [labeledDocument?.type?.fullName]
  );

  return (
    <li css={styles.item}>
      <div className="flex flex-row items-center gap-2" css={styles.labelName} style={bgStyle}>
        <StatusSelect value={labeledDocument.status} onValueChange={handleChangeStatus} />
        <span css={styles.labelNameText}>
          {labeledDocument.type.abbreviatedName
            ? labeledDocument.type.abbreviatedName
            : labeledDocument.type.fullName}{" "}
          {labelUser ? `(${labelUser}) ` : ""}
          {labelDebts?.length ? `[${labelDebts?.join(", ")}]` : ""}
        </span>
        <button onClick={handleClickNote}>
          <NotebookPen
            className={cn(
              "w-[16px] h-[16px] hover:brightness-90 text-white",
              labeledDocument?.notes?.length ? "text-green" : undefined
            )}
          />
        </button>
        <XButton onClick={handleDeletion} color={colors.palette.WHITE} style={styles.deleteButton} />
      </div>
      <ul css={styles.labelSubdocuments}>
        {groupedByDocument.map(([documentName, pages]) => (
          <li key={pages[0]?.document.id}>
            <div css={styles.subdocumentName}>{pages[0]?.document.file.name}</div>
          </li>
        ))}
      </ul>
    </li>
  );
};

const colorMap = {
  [enums.ApplicationTaskApprovalStatus.NEEDS_WORK]: "bg-orange",
  [enums.ApplicationTaskApprovalStatus.REQUESTED]: "bg-gray",
  [enums.ApplicationTaskApprovalStatus.APPROVED]: "bg-green",
  [enums.ApplicationTaskApprovalStatus.REJECTED]: "bg-red",
};

export const StatusIconWithText = ({ status }: { status?: enums.ApplicationTaskApprovalStatus }) => {
  if (!status) {
    return null;
  }
  return (
    <div
      className={cn("flex flex-row items-center gap-0.5 px-1.5 pr-2 rounded-full py-0.5", colorMap[status])}
    >
      <StatusIcon size="sm" status={status} />
      <p className="text-[11px] text-white">{_.startCase(status)}</p>
    </div>
  );
};

const sizeMap = {
  sm: "min-w-[17px] min-h-[17px] w-[17px] h-[17px]",
  md: "min-w-[20px] min-h-[20px] w-[20px] h-[20px]",
};

export const StatusIcon = ({
  status,
  className,
  size = "md",
}: { status?: enums.ApplicationTaskApprovalStatus; className?: string; size?: "sm" | "md" }) => {
  if (status === enums.ApplicationTaskApprovalStatus.REJECTED) {
    return (
      <CircleX strokeWidth={1} className={cn("rounded-full bg-red text-white", sizeMap[size], className)} />
    );
  }
  if (status === enums.ApplicationTaskApprovalStatus.APPROVED) {
    return (
      <CircleCheck
        strokeWidth={1}
        className={cn("rounded-full bg-green text-white", sizeMap[size], className)}
      />
    );
  }
  if (status === enums.ApplicationTaskApprovalStatus.NEEDS_WORK) {
    return (
      <CircleMinus
        strokeWidth={1}
        className={cn("rounded-full bg-orange text-white", sizeMap[size], className)}
      />
    );
  }
  if (status === enums.ApplicationTaskApprovalStatus.REQUESTED) {
    return (
      <CircleHelp
        strokeWidth={1}
        className={cn("bg-gray rounded-full text-white", sizeMap[size], className)}
      />
    );
  }
  return null;
};

const StatusSelect = ({
  value,
  onValueChange,
}: {
  value?: enums.ApplicationTaskApprovalStatus;
  onValueChange: (item: enums.ApplicationTaskApprovalStatus) => void;
}) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger
        rightIcon={<div />}
        className="w-[20px] h-[20px] bg-white rounded-full p-0 flex items-center justify-center z-10"
      >
        <SelectValue>
          <StatusIcon status={value} />
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={enums.ApplicationTaskApprovalStatus.REJECTED}>
          <div className="flex flex-row gap-2">
            <StatusIcon status={enums.ApplicationTaskApprovalStatus.REJECTED} />
            Rejected
          </div>
        </SelectItem>
        <SelectItem value={enums.ApplicationTaskApprovalStatus.APPROVED}>
          <div className="flex flex-row gap-2">
            <StatusIcon status={enums.ApplicationTaskApprovalStatus.APPROVED} />
            Approved
          </div>
        </SelectItem>
        <SelectItem value={enums.ApplicationTaskApprovalStatus.NEEDS_WORK}>
          <div className="flex flex-row gap-2">
            <StatusIcon status={enums.ApplicationTaskApprovalStatus.NEEDS_WORK} />
            Needs Work
          </div>
        </SelectItem>
        <SelectItem value={enums.ApplicationTaskApprovalStatus.REQUESTED}>
          <div className="flex flex-row gap-2">
            <StatusIcon status={enums.ApplicationTaskApprovalStatus.REQUESTED} />
            Requested
          </div>
        </SelectItem>
      </SelectContent>
    </Select>
  );
};

export default LabeledDocumentItem;
