import { enums, getSyntheticStatusesAfterStatus } from "@fraction/shared";
import _ from "lodash";
import { useMemo } from "react";
import { useChecklistModal } from "src/components/AppChecklistModal";
import { AppsPipelineView } from "src/components/AppsPipelineView";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useInfiniteApplicationsQuery } from "src/hooks/useApplication";

export const LenderDashboardOverview = ({ status = "active" }: { status?: "active" | "all" | "closed" }) => {
  useDocumentTitle("Dashboard Overview");
  const {
    data: applications,
    count,
    dataUpdatedAt,
    refetch,
    isFetching,
  } = useInfiniteApplicationsQuery({
    status,
    accountType: "lender",
  });
  const closeDateSet = useMemo(() => applications?.filter((app) => !!app.estimatedCloseDate), [applications]);
  const closeDateNotSet = useMemo(
    () => applications?.filter((app) => !app.estimatedCloseDate),
    [applications]
  );
  const appsSortedByClosing = useMemo(
    () => [..._.sortBy(closeDateSet, "estimatedCloseDate"), ...(closeDateNotSet || [])],
    [closeDateSet, closeDateNotSet]
  );

  const { setShowModal, ChecklistModal } = useChecklistModal();

  return (
    <AppsPipelineView
      apps={appsSortedByClosing}
      isFetching={isFetching}
      handleRefetchClick={refetch}
      totalCount={count}
      dataUpdatedAt={dataUpdatedAt}
      setShowModal={setShowModal}
      modal={<ChecklistModal showRetoolButton={false} />}
      groupBy="syntheticStatus"
      className="pt-6 pl-6"
      visibleStages={getSyntheticStatusesAfterStatus(
        enums.ApplicationStatus.SUBMITTED_TO_WHITELABEL_LENDER,
        true
      )}
      showChecklist={false}
    />
  );
};
