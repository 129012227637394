import { SyntheticApplicationStatus, enums, isStatusAfterStatus, selectors } from "@fraction/shared";
import _ from "lodash";
import { useMemo } from "react";
import { useChecklistModal } from "src/components/AppChecklistModal";
import { AppsPipelineView } from "src/components/AppsPipelineView";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useInfiniteApplicationsQuery } from "src/hooks/useApplication";

export const ConveyancerDashboardOverview = ({
  status = "active",
}: { status?: "active" | "all" | "closed" }) => {
  useDocumentTitle("Dashboard Overview");
  const {
    data: applications,
    count,
    dataUpdatedAt,
    refetch,
    isFetching,
  } = useInfiniteApplicationsQuery({
    status,
    accountType: "conveyancer",
  });
  const closeDateSet = useMemo(() => applications?.filter((app) => !!app.estimatedCloseDate), [applications]);
  const closeDateNotSet = useMemo(
    () => applications?.filter((app) => !app.estimatedCloseDate),
    [applications]
  );
  const appsSortedByClosing = useMemo(
    () => [..._.sortBy(closeDateSet, "estimatedCloseDate"), ...(closeDateNotSet || [])],
    [closeDateSet, closeDateNotSet]
  );

  const conveyancerEngagedOnly = useMemo(
    () =>
      appsSortedByClosing
        .filter((app) => {
          return (
            app.status && isStatusAfterStatus(app.status, enums.ApplicationStatus.CONVEYANCER_ENGAGED, true)
          );
        })
        .map(selectors.application.selectApplicationWithSyntheticStatus),
    [appsSortedByClosing]
  );

  const { setShowModal, ChecklistModal } = useChecklistModal();

  return (
    <AppsPipelineView
      apps={conveyancerEngagedOnly}
      isFetching={isFetching}
      handleRefetchClick={refetch}
      totalCount={count}
      dataUpdatedAt={dataUpdatedAt}
      setShowModal={setShowModal}
      modal={<ChecklistModal showRetoolButton={false} />}
      groupBy="syntheticStatus"
      className="pt-6 pl-6"
      visibleStages={[
        SyntheticApplicationStatus.CONVEYANCER_ENGAGED,
        SyntheticApplicationStatus.CONVEYANCER_REACHED_OUT_TO_ILR,
        SyntheticApplicationStatus.INSTRUCTIONS_SENT_TO_ILR,
        SyntheticApplicationStatus.PARTIAL_DOCS_RECEIVED,
        SyntheticApplicationStatus.READY_FOR_CAPITAL_CALL,
        SyntheticApplicationStatus.CAPITAL_CALLED,
        SyntheticApplicationStatus.READY_FOR_DRAW,
        SyntheticApplicationStatus.FUNDED,
      ]}
      showChecklist={false}
    />
  );
};
