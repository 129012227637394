import {
  SyntheticApplicationStatus,
  calculators,
  documents,
  enums,
  selectors,
  types,
} from "@fraction/shared";
import Chip from "src/components/Chip";
import { DocumentChecklistRow } from "src/components/DocumentChecklistRow";
import Skeleton from "src/components/Skeleton";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { useApplicationAuthed } from "src/hooks/useApplication";
import { useChecklist } from "src/hooks/useChecklist";
import { Document } from "src/images";
import { cn } from "src/utilities/shadcnUtils";

export function DocumentChecklist({
  applicationId,
  onClickComplexUpload,
  className,
  status,
}: {
  applicationId?: string;
  onClickComplexUpload?: () => void;
  className?: string;
  status?: SyntheticApplicationStatus | enums.LoanStatus;
}) {
  const { data: app, isLoading } = useApplicationAuthed({
    id: applicationId,
  });

  const {
    data: checklist,
    refetch: refetchChecklist,
    isLoading: checklistLoading,
  } = useChecklist({
    id: applicationId,
    status:
      status ||
      (app?.loan?.status
        ? app?.loan?.status
        : app && selectors.application.isInactiveDeal(app)
        ? app?.status
        : // if its a fraction deal, show up to READY FOR DRAW,
        // but if its a non-fraction deal, show up to READY FOR INSTRUCTIONS since they will collect the rest
        app?.lenderId === enums.LenderName.FRACTION
        ? enums.ApplicationStatus.READY_FOR_DRAW
        : enums.ApplicationStatus.READY_FOR_INSTRUCTIONS),
  });

  const { preference } = useAccountViewPreference();

  const allDebts = app ? calculators.application.selectAllDebts(app) : [];

  const activeLoanAndEmployee = preference === "employee" && !!app?.loan?.id;

  const docChecklist = checklist
    ?.filter(types.isDocumentChecklistItem)
    ?.filter((item) =>
      activeLoanAndEmployee ? true : item.status !== enums.ApplicationTaskStatus.NOT_APPLICABLE
    )
    ?.filter((item) => documents.shouldShowDocumentChecklistItem(item, preference));

  return (
    <div className={cn("border p-6 border-gray-400 rounded max-h-[700px] overflow-y-scroll", className)}>
      <div className="flex flex-row justify-between items-center mb-3">
        <p className="text-2xl font-bold">Document checklist</p>

        {onClickComplexUpload ? (
          <button onClick={onClickComplexUpload}>
            <Chip className="hover:bg-green-400" variant="green">
              Upload complex
            </Chip>
          </button>
        ) : null}
      </div>
      <p className="text-md mb-3">
        These are the various documents that will need to be collected throughout the application process. If
        you don't know what they are, don't worry, we will reach out when we need each document!
      </p>
      <div className="flex flex-col">
        {(!docChecklist?.length && checklistLoading) || isLoading ? (
          Array.from({ length: 6 }).map((_, i) => <Skeleton className="w-full h-11" key={i} />)
        ) : !docChecklist?.length ? (
          <div className="h-60 w-full flex flex-col items-center justify-center">
            <Document width="100%" height={70} />
            <p className="text-sm text-gray-700 mt-2 font-light text-center">
              No more documents are
              <br /> currently needed for this file
            </p>
          </div>
        ) : (
          docChecklist?.map((item, idx) =>
            applicationId ? (
              <DocumentChecklistRow
                onUploadSuccess={refetchChecklist}
                applicationId={applicationId}
                key={idx}
                item={item}
                allDebts={allDebts}
                canUpload={documents.canUploadDocumentChecklistItem(item, preference)}
              />
            ) : null
          )
        )}
      </div>
    </div>
  );
}
