import { DocumentType, parsers } from "@fraction/shared";
import _ from "lodash";
import { useState } from "react";
import fraction from "src/api/fraction";
import { useMutation } from "src/lib";
import { downloadFile, downloadFilePost } from "src/utilities/file/download";

const docGenTypeMap: Partial<Record<DocumentType, parsers.docGen.DocGenDocumentType>> = {
  [DocumentType.COMMITMENT_LETTER]: parsers.docGen.DocGenDocumentType.COMMITMENT_LETTER,
  [DocumentType.SIGNED_COMMITMENT_LETTER]: parsers.docGen.DocGenDocumentType.COMMITMENT_LETTER,
  [DocumentType.UNSIGNED_COMMITMENT_LETTER]: parsers.docGen.DocGenDocumentType.COMMITMENT_LETTER,
  [DocumentType.FRACTION_DISCLOSURE_2B]: parsers.docGen.DocGenDocumentType.BORROWER_DISCLOSURE,
  [DocumentType.UNSIGNED_FRACTION_DISCLOSURE_2B]: parsers.docGen.DocGenDocumentType.BORROWER_DISCLOSURE,
  [DocumentType.FRACTION_LOAN_AGREEMENT]: parsers.docGen.DocGenDocumentType.LOAN_AGREEMENT,
  [DocumentType.UNSIGNED_FRACTION_LOAN_AGREEMENT]: parsers.docGen.DocGenDocumentType.LOAN_AGREEMENT,
};

export function useDocgen() {
  const [typeLoading, setTypeLoading] = useState<string | undefined>();

  const generateDocument = useMutation({
    mutationFn: async ({ type, applicationId }: { type: DocumentType; applicationId: string }) => {
      const docGenType = docGenTypeMap[type];
      if (!docGenType) {
        throw new Error("Document type not supported for generation");
      }
      try {
        setTypeLoading(type);
        const { url, headers, body } = fraction.generateDocumentUrl({ type: docGenType, applicationId });
        await downloadFilePost({
          url,
          headers,
          body,
          fileName: `${_.startCase(type)}.pdf`,
        });
      } finally {
        setTypeLoading(undefined);
      }
    },
  });

  return {
    typeLoading: generateDocument.isPending ? typeLoading : undefined,
    ...generateDocument,
  };
}

export function useDocumentDownload() {
  const [typeLoading, setTypeLoading] = useState<string | undefined>();

  const generateDocument = useMutation({
    mutationFn: async ({ documentId, type }: { documentId: string; type: DocumentType }) => {
      try {
        setTypeLoading(type);
        const url = await fraction.getPresignedFileDownloadUrl(documentId);
        await downloadFile(url, `${_.startCase(type)}.pdf`);
      } finally {
        setTypeLoading(undefined);
      }
    },
  });

  return {
    typeLoading: generateDocument.isPending ? typeLoading : undefined,
    ...generateDocument,
  };
}
