import { UndefinedInitialDataInfiniteOptions, useQueryClient } from "@tanstack/react-query";
import { isFuture, isSameDay, isThisMonth, lastDayOfMonth, parseISO, startOfDay } from "date-fns";
import { toast } from "react-toastify";
import fraction, { ChecklistApp } from "src/api/fraction";
import { captureException } from "src/api/sentry";
import { APPLICATION_KEY, useApplicationQuery, useApplicationsQuery } from "src/hooks/useApplication";
import { useCachedQuery, useCachedQueryClient, useCachedState } from "src/hooks/useCache";
import { UseQueryOptions, useMutation, useQuery } from "src/lib";
import { Logger } from "src/log";
import { Response } from "superagent";

import {
  ForbiddenTransactionError,
  IncorrectInputsError,
  Saved,
  SyntheticApplicationStatus,
  calculators,
  entities,
  enums,
  formatters,
  notUndefinedOrNull,
  parsers,
  plainToInstance,
  types,
  utilities,
} from "@fraction/shared";
import _ from "lodash";
import { useMemo, useState } from "react";

const LOGGER = new Logger("PostFundedDashboard.queries");

export interface TransactionHistoryRecord {
  amount: number;
  date: Date;
  receivingAccount?: entities.BankAccount;
  status: enums.TransactionStatus;
  type: enums.TransactionType;
  direction: enums.TransactionDirection;
  periodStart?: Date;
  periodEnd?: Date;
}

export type ChecklistLoan = entities.LoanT & {
  application?: ChecklistApp;
  // these are fetched within sql, see fetchers/application in the loanDetails section
  overdueBalance?: number;
  paymentIntervalsElapsed?: number;
  unpaidInterest?: number;
  nextPaymentDate?: Date;
  nextPaymentAmount?: number;
  payoffAmount?: number;
};

const selectLoanData = (data: ChecklistApp[]): ChecklistLoan => {
  const application = data?.find((app) => app.loan?.id);
  if (!application?.loan) {
    throw new Error("Application response is missing required loan data");
  }
  const parseResult = parsers.loan.DASHBOARD_LOAN.safeParse(application.loan);
  if (!parseResult.success) {
    LOGGER.exception(parseResult.error, "Application response loan parsing error");
    captureException(parseResult.error);
  }

  // sort the statements from newest to oldest
  application.loan.statements?.sort(
    (a, b) => (b?.periodEndDate?.valueOf() || 0) - (a?.periodEndDate?.valueOf() || 0)
  );

  const { loan, ...rest } = application;
  return {
    ...loan,
    // @ts-ignore
    nextPaymentDate: loan.nextPaymentDate ? new Date(loan.nextPaymentDate) : undefined,
    application: rest,
  };
};

const selectTransactionData = ({
  data,
  monthlyAggregatedTransactions,
}: parsers.transactionRecord.FetchTransactionRecordsResponse) => {
  const shapedTransactions = data.map(
    ({ id, amount, transactionDate, receivingAccount, status, type, direction }) => ({
      id,
      amount,
      date: transactionDate,
      receivingAccount,
      status,
      type,
      direction,
    })
  );

  const monthlyInterests = Object.values(monthlyAggregatedTransactions).reduce<
    Record<
      string,
      {
        amount: number;
        direction: enums.TransactionDirection;
        transactionDate: Date;
        periodStart: Date;
        periodEnd: Date;
      }
    >
  >((acc, value) => {
    for (const { month, amount, direction, periodEnd, periodStart } of value) {
      const key = formatters.date.iso8601(startOfDay(month), "month");

      const amt =
        (acc[key]?.amount || 0) +
        utilities.transaction.getSignedAmountFromTransactionDirection({ amount, direction });
      acc[key] = {
        periodEnd,
        periodStart,
        transactionDate: month,
        amount: amt,
        direction: utilities.transaction.getTransactionDirectionFromAmount(amt),
      };
    }
    return acc;
  }, {});

  const mostRecentTransactionDate = _.maxBy(
    [...data, ...Object.values(monthlyInterests)],
    "transactionDate"
  )?.transactionDate;
  const mostRecentTxOrToday =
    !mostRecentTransactionDate || isFuture(mostRecentTransactionDate)
      ? new Date()
      : mostRecentTransactionDate;

  const shapedMonthlyInterest = Object.entries(monthlyInterests)
    .map(([monthStr, { amount, direction, periodEnd, periodStart }]) => {
      const month = parseISO(monthStr);
      return {
        amount,
        direction,
        // we show borrower's monthly interest as a transaction on the last day of the period
        // if it is this month, we show them the most recent transaction
        // date that is less than or equal to today
        date: isThisMonth(month) ? startOfDay(mostRecentTxOrToday) : startOfDay(lastDayOfMonth(month)),
        status: enums.TransactionStatus.COMPLETED,
        type: enums.TransactionType.INTEREST_ACCUMULATION,
        periodStart,
        periodEnd,
      };
    })
    .filter(({ amount }) => amount !== 0);

  const originalLoanDrawTx = _.minBy(
    shapedTransactions.filter((tx) => tx.type === enums.TransactionType.DRAW),
    "createdAt"
  );
  const withoutLoanDrawTx = shapedTransactions.filter((tx) => tx.id !== originalLoanDrawTx?.id);

  /*
   * We merge the transaction records and the monthly aggregated interest into a common data structure.
   * This allows us to insert both data types into the same transaction history table.
   */
  const merged = [...withoutLoanDrawTx, ...shapedMonthlyInterest] as TransactionHistoryRecord[];

  return [
    ...merged
      .filter((tx) => tx.date && (isSameDay(tx.date, new Date()) || !isFuture(tx.date))) // filter out future dated txs which can happen with monthly interest accrual
      .sort((a, b) => b.date.getTime() - a.date.getTime()),
    originalLoanDrawTx as TransactionHistoryRecord,
  ].filter(notUndefinedOrNull);
};

export const useMutateApplicationStatus = () => {
  const queryClient = useCachedQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      status,
      force,
    }: { id: string; status: SyntheticApplicationStatus; force?: boolean }) =>
      fraction.setApplicationStatus({ id, status, force }),
    onSuccess: (resp) => {
      for (const app of resp.succeeded) {
        const updater = (prev: any) => {
          if (!prev) {
            return prev;
          }
          return {
            ...(prev || {}),
            data: prev?.data?.map((oldapp: entities.Application) => {
              if (oldapp.id !== app.applicationId) {
                return oldapp;
              }
              return {
                ...oldapp,
                status: app.status,
                syntheticStatus: app.syntheticStatus,
                updatedAt: new Date(),
              };
            }),
          };
        };
        queryClient.setQueryData([...APPLICATION_KEY, app.applicationId], (prev: any) => ({
          ...prev,
          status: app.status,
          syntheticStatus: app.syntheticStatus,
          updatedAt: new Date(),
        }));
        queryClient.setQueryData([...APPLICATION_KEY, "active", "employee"], updater);
        queryClient.setQueryData([...APPLICATION_KEY, "infinite", "active", "employee"], updater);
      }
    },
  });
};

export const useLoanQuery = (
  options?: Partial<UndefinedInitialDataInfiniteOptions<any>> & { initialRefetch?: boolean }
) => {
  const { data, ...rest } = useApplicationsQuery({ accountType: "applicant", status: "closed" }, options);
  const onlyOneDataLoanId = data?.length === 1 ? data?.[0]?.loan?.id : undefined;
  const [selectedLoanId, _, isLoadingCache] = useCachedState(onlyOneDataLoanId, "selected-loan-id");
  const filteredData = selectedLoanId ? data?.filter((data) => data.loan?.id === selectedLoanId) : data;

  const newData = useMemo(
    () => (filteredData?.length ? selectLoanData(filteredData) : undefined),
    [filteredData]
  );

  const specificDetails = useApplicationQuery({
    accountType: "applicant",
    placeholderData: data?.find((x) => x.id === newData?.applicationId),
    id: newData?.applicationId,
    initialRefetch: options?.initialRefetch,
  });
  const specificDetailsLoan = specificDetails.data ? selectLoanData([specificDetails.data]) : undefined;

  return {
    ...rest,
    isLoading: rest.isLoading || isLoadingCache || !newData?.applicationId,
    isFetching: rest.isFetching || specificDetails.isFetching || isLoadingCache || !newData?.applicationId,
    data: specificDetailsLoan || newData,
  };
};

export const useMonthlyStatementQuery = (
  fileID: string,
  {
    onSuccess,
    ...options
  }: Omit<UseQueryOptions<types.FileURL>, "queryKey"> & { onSuccess?: (file: types.FileURL) => void }
) =>
  useQuery({
    queryKey: ["monthlyStatement", fileID],
    queryFn: async () => {
      const statement = await fraction.getMonthlyStatement(fileID);
      if (onSuccess) {
        await onSuccess(statement);
      }
      return statement;
    },
    enabled: false,
    ...options,
  });

export const useTransactionsQuery = (loanId: string) => {
  return useQuery<
    parsers.transactionRecord.FetchTransactionRecordsResponse,
    unknown,
    TransactionHistoryRecord[]
  >({
    queryKey: TRANSACTIONS_KEY,
    queryFn: () =>
      fraction.getTransactionRecords(loanId, {
        transactionTypes: calculators.transaction.TYPES_EXCLUDING_INTEREST,
        monthlyAggregatedTypes: calculators.transaction.TYPES_INTEREST,
      }),
    enabled: !!loanId,
    select: selectTransactionData,
  });
};

export const useBankAccountsQuery = (options?: UseQueryOptions<entities.BankAccount[]>) =>
  useQuery({
    queryKey: BANK_ACCOUNTS_KEY,
    queryFn: () => fraction.getBankAccounts(),
    ...options,
  });

export interface MutationOptions {
  onSuccess?: () => void;
}

export const useCreateBankAccountMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: parsers.bankAccount.BankAccountSubmission) => fraction.createBankAccount(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: BANK_ACCOUNTS_KEY });
    },
    errorMessage: (error) => {
      if (error?.response?.statusCode === 422 && error?.response?.body?.message) {
        return error?.response?.body?.message;
      }
    },
  });
};

export const useEditBankAccountMutation = (bankAccountId: string = "") => {
  const queryClient = useQueryClient();
  return useMutation<Response, any, parsers.bankAccount.PatchBankAccountSubmission>({
    mutationFn: (data) => fraction.editBankAccount(bankAccountId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: BANK_ACCOUNTS_KEY });
    },
  });
};

export const useDeleteBankAccountMutation = (bankAccountId: string = "") => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => fraction.deleteBankAccount(bankAccountId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: BANK_ACCOUNTS_KEY });
    },
  });
};

export const useVerificationMutation = (
  bankAccountId: string | undefined,
  {
    onExpiredDepositsError,
  }: MutationOptions & {
    onExpiredDepositsError: () => void;
  }
) => {
  const queryClient = useQueryClient();
  return useMutation<Response | undefined, any, parsers.modernTreasury.Verification>({
    mutationFn: (amounts) =>
      bankAccountId ? fraction.verifyBankAccount(bankAccountId, amounts) : Promise.resolve(undefined),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: BANK_ACCOUNTS_KEY });
    },
    errorMessage: (error) => {
      const errorResponse = error?.response;
      const errorMessage = errorResponse?.body?.message;

      if (errorResponse?.body?.name === "BankAccountVerificationResentError") {
        onExpiredDepositsError();
        return "";
      }

      if (errorResponse?.statusCode === 422) {
        return errorMessage === "The entered amounts are invalid"
          ? errorMessage
          : "Unfortunately, we were unable to verify the bank account";
      }
    },
  });
};

export const usePaymentsMutation = (idempotencyKey: string, { onError }: { onError?: () => void } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: parsers.payment.PaymentOrder) => fraction.createPaymentOrder(data, idempotencyKey),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: TRANSACTIONS_KEY });
      queryClient.invalidateQueries({ queryKey: APPLICATION_KEY }); // loan balance and other data will have changed
    },
    onError: (error) => {
      // We invalidate the transactions if the error is a 5xx in case the request was partially successfull and a payment order was created.
      if (error?.response?.statusCode >= 500) {
        queryClient.invalidateQueries({ queryKey: TRANSACTIONS_KEY });
      }
      if (error instanceof ForbiddenTransactionError) {
        toast.error(error.message);
      } else {
        toast.error("There was an error processing your payment. Please try again later.");
      }
      onError?.();
    },
  });
};

export const usePaymentSubscriptionQuery = (loanId: string) =>
  useQuery<parsers.paymentSubscription.UsablePaymentSubscription[]>({
    queryKey: SUBSCRIPTION_KEY,
    enabled: !!loanId,
    queryFn: () => fraction.getPaymentSubscriptions(loanId),
  });

export const useCreatePaymentSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: parsers.paymentSubscription.PaymentSubscriptionBody) =>
      fraction.createPaymentSubscription(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: SUBSCRIPTION_KEY });
    },
  });
};

export const useDeletePaymentSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => fraction.deletePaymentSubscription(id),
    onSuccess: () => {
      /*
       * Since currently the borrower may only have one subscription, if they delete a subscription that means
       * they will no longer have any subscriptions. So we can set the cache to empty.
       */
      queryClient.invalidateQueries({ queryKey: SUBSCRIPTION_KEY });
    },
  });
};

export const useDeleteDocuments = ({ onSuccess }: { onSuccess?: () => void }) => {
  const [documentDeleting, setDocumentDeleting] = useState<string | null>();

  const queryClient = useQueryClient();
  const m = useMutation({
    mutationFn: async (id: string) => {
      setDocumentDeleting(id);
      try {
        return await fraction.deleteFile(id, { force: true });
      } finally {
        setDocumentDeleting(null);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: DOCUMENTS_KEY });
      onSuccess?.();
    },
  });

  return {
    ...m,
    documentDeleting,
  };
};

export const useDocumentsLocal = (
  applicationId: string | undefined,
  opts: { enabled?: boolean; status?: enums.ApplicationTaskApprovalStatus | null } = {
    enabled: true,
  }
) => {
  const queryClient = useCachedQueryClient();
  const key = [...DOCUMENTS_KEY, applicationId];

  return useQuery<Saved<entities.UploadedFile>[] | undefined | null>({
    queryKey: [...key, "local"],
    queryFn: () => queryClient.getQueryData(key) || null,
    enabled: opts.enabled && !!applicationId,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
  });
};

export const useDocumentsQuery = (
  applicationId: string | undefined,
  { enabled = true, refetch = true }: { enabled?: boolean; refetch?: boolean } = {
    enabled: true,
    refetch: true,
  }
) => {
  const queryClient = useCachedQueryClient();
  const queries: Record<string, any> = {};
  const queryKey = [...DOCUMENTS_KEY, applicationId];
  return useCachedQuery({
    initialRefetch: true,
    refetchInterval: refetch ? undefined : false,
    refetchOnMount: refetch,
    refetchOnReconnect: refetch,
    refetchOnWindowFocus: refetch,
    refetchIntervalInBackground: refetch,
    queryKey,
    deserialize: (data) => plainToInstance(entities.UploadedFile, data),
    enabled: !!applicationId && enabled,
    queryFn: () => (applicationId ? fraction.getApplicationDocuments(applicationId, queries) : null),
    select: (data) => _.sortBy(data, "date").reverse(),
    onSuccess: (data) => {
      queryClient.setQueryData([...queryKey, "local"], data);
    },
  });
};

export const useChangeDocumentStatusMutation = (file?: entities.UploadedFile) => {
  const [mutatingStatus, setMutatingStatus] = useState<string | null>();
  const queryClient = useCachedQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      status,
      notes,
    }: { status: enums.ApplicationTaskApprovalStatus; notes?: string }) => {
      setMutatingStatus(status);
      try {
        if (!file?.id) {
          return;
        }
        queryClient.setQueriesData(
          { exact: false, queryKey: [...DOCUMENTS_KEY, file?.applicationId] },
          (prev: entities.UploadedFile[] | undefined) =>
            prev?.map((item) => {
              if (item.id === file?.id) {
                return new entities.UploadedFile({
                  ...item,
                  status,
                  notes,
                });
              }
              return item;
            })
        );
        await fraction.setDocumentStatusAndNotes({ id: file?.id, status, notes });
      } finally {
        setMutatingStatus(null);
      }
    },
  });

  return {
    ...mutation,
    isPendingStatus: mutatingStatus,
  };
};

export const useDocumentUrlQuery = (
  fileID: string,
  {
    onSuccess,
    ...options
  }: Omit<UseQueryOptions<types.FileURL>, "queryKey"> & { onSuccess?: (file: types.FileURL) => void }
) =>
  useQuery({
    queryKey: ["application_document", fileID],
    queryFn: async () => {
      const doc = await fraction.getApplicationDocumentUrl(fileID);
      if (onSuccess) {
        await onSuccess(doc);
      }
      return doc;
    },
    enabled: false,
    ...options,
  });

export const useLoanPackageQuery = (
  applicationId: string | undefined,
  {
    onSuccess,
    ...options
  }: Omit<UseQueryOptions<types.FileURL>, "queryKey"> & { onSuccess?: (file: types.FileURL) => void }
) =>
  useQuery({
    queryKey: ["application_loan_package", applicationId],
    enabled: false,
    queryFn: async () => {
      if (!applicationId) {
        throw new IncorrectInputsError("Need application ID for loan package query");
      }
      const doc = await fraction.getApplicationLoanPackage(applicationId);
      if (onSuccess) {
        await onSuccess(doc);
      }
      return doc;
    },
    ...options,
  });

export const useLoanDrawMutation = (loanId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: parsers.loanDraw.LoanDraw) => fraction.createLoanDraw(loanId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: TRANSACTIONS_KEY });
    },
    errorMessage: (error) => {
      if (error?.response?.statusCode === 429) {
        return "Too many requests were made at once. Please try again in a few minutes.";
      }
    },
  });
};

export const usePrepaymentMutation = (loanId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ type }: { type: types.PrepaymentType }) => fraction.createPrepayment(loanId, { type }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: TRANSACTIONS_KEY });
    },
    errorMessage: (error) => {
      if (error?.response?.statusCode === 429) {
        return "Too many requests were made at once. Please try again in a few minutes.";
      }
    },
  });
};

export const BANK_ACCOUNTS_KEY = ["bankAccounts"];
export const TRANSACTIONS_KEY = ["transactions"];
export const SUBSCRIPTION_KEY = ["subscription_key"];
export const DOCUMENTS_KEY = ["application_documents"];
